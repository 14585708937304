import $ from 'jquery';
import Auth from 'j-toker';

export function apiGet(urlOrPath, data) {
  const fullUrl = `${Auth.getApiUrl()}${urlOrPath}`;
  const url = urlOrPath.startsWith('/') ? fullUrl : urlOrPath;

  $.ajaxSetup({
    beforeSend: function (xhr, settings) {
      Auth.appendAuthHeaders(xhr, settings);
    },
  });
  return $.ajax({
    url: url,
    data: data,
  });
}

export function apiPost(urlOrPath, data, signal) {
  const fullUrl = `${Auth.getApiUrl()}${urlOrPath}`;
  const url = urlOrPath.startsWith('/') ? fullUrl : urlOrPath;

  $.ajaxSetup({
    beforeSend: function (xhr, settings) {
      Auth.appendAuthHeaders(xhr, settings);
    },
  });
  return $.ajax({
    url: url,
    type: 'POST',
    data: JSON.stringify(data),
    contentType: 'application/json',
  });
}

export function apiPatch(urlOrPath, data) {
  const fullUrl = `${Auth.getApiUrl()}${urlOrPath}`;
  const url = urlOrPath.startsWith('/') ? fullUrl : urlOrPath;

  $.ajaxSetup({
    beforeSend: function (xhr, settings) {
      Auth.appendAuthHeaders(xhr, settings);
    },
  });
  return $.ajax({
    url: url,
    type: 'PATCH',
    data: JSON.stringify(data),
    contentType: 'application/json',
  });
}

/**
 * @deprecated use apiPutRequest instead
 *
 * Makes a PUT request to an HTTP server
 *
 * @param {string} url - The URL to send the request to
 * @param {object} data - The data payload to send to the server
 * @returns {Promise} - A promise that resolves with the response data
 *
 * Warning: please check if you need to manually prefix
 *  `/v1/landlords/` to the beginning of the URL when using this method
 *
 * @example
 * import Auth from 'j-toker';
 * apiPut(`${Auth.getApiUrl()}/my/endpoint`, data)
 */
export function apiPut(url, data) {
  $.ajaxSetup({
    beforeSend: function (xhr, settings) {
      Auth.appendAuthHeaders(xhr, settings);
    },
  });
  return $.ajax({
    url: url,
    type: 'PUT',
    data: JSON.stringify(data),
    contentType: 'application/json',
  });
}

/**
 * Makes a PUT request to an HTTP server
 * @param {string} urlOrPath - The URL (or path) to send the request to
 * @param {object} data - payload to send to the server
 * @returns {Promise} - A promise that resolves with the response data
 * @example
 * apiPutRequest('my/endpoint', data)
 */
export function apiPutRequest(urlOrPath, data) {
  const fullUrl = `${Auth.getApiUrl()}${urlOrPath}`;
  const url = urlOrPath.startsWith('/') ? fullUrl : urlOrPath;

  $.ajaxSetup({
    beforeSend: function (xhr, settings) {
      Auth.appendAuthHeaders(xhr, settings);
    },
  });
  return $.ajax({
    url: url,
    type: 'PUT',
    data: JSON.stringify(data),
    contentType: 'application/json',
  });
}

export function apiPostMultipart(urlOrPath, formData) {
  const fullUrl = `${Auth.getApiUrl()}${urlOrPath}`;
  const url = urlOrPath.startsWith('/') ? fullUrl : urlOrPath;

  return $.ajax({
    url: url,
    data: formData,
    contentType: false,
    processData: false,
    type: 'POST',
  });
}

export function apiPutMultipart(urlOrPath, formData) {
  const fullUrl = `${Auth.getApiUrl()}${urlOrPath}`;
  const url = urlOrPath.startsWith('/') ? fullUrl : urlOrPath;

  return $.ajax({
    url: url,
    data: formData,
    contentType: false,
    processData: false,
    type: 'PUT',
  });
}

/**
 * @deprecated use apiDeleteRequest instead
 *
 * Makes a DELETE request to an HTTP server
 *
 * @param {string} url - The URL to send the request to
 * @returns {Promise} - A promise that resolves with the response data
 *
 * Warning: please check if you need to manually prefix
 *  `/v1/landlords/` to the beginning of the URL when using this method
 *
 * @example
 * import Auth from 'j-toker';
 * apiDelete(`${Auth.getApiUrl()}/my/endpoint`)
 */
export function apiDelete(url) {
  $.ajaxSetup({
    beforeSend: function (xhr, settings) {
      Auth.appendAuthHeaders(xhr, settings);
    },
  });
  return $.ajax({
    url: url,
    type: 'DELETE',
    contentType: 'application/json',
  });
}

/**
 * Makes a DELETE request to an HTTP server
 * @param {string} urlOrPath - The URL (or path) to send the request to
 * @param {object} data - payload to send to the server
 * @returns {Promise} - A promise that resolves with the response data
 * @example
 * apiDeleteRequest('my/endpoint', data)
 */
export function apiDeleteRequest(urlOrPath, data) {
  const fullUrl = `${Auth.getApiUrl()}${urlOrPath}`;
  const url = urlOrPath.startsWith('/') ? fullUrl : urlOrPath;

  $.ajaxSetup({
    beforeSend: function (xhr, settings) {
      Auth.appendAuthHeaders(xhr, settings);
    },
  });
  return $.ajax({
    url: url,
    data: JSON.stringify(data),
    type: 'DELETE',
    contentType: 'application/json',
  });
}
