import { updateLanguage } from 'react-localize-redux';
import { setGlobalTranslations } from 'react-localize-redux';
import store from '../store';
import { padDetailsTypes } from './constants';

export function languageSetup() {
  const searchParams = new URLSearchParams(window.location.search);

  const json = {
    en: require('../assets/localization/translations/en.locale.json'),
    fr: require('../assets/localization/translations/fr.locale.json'),
    nl: require('../assets/localization/translations/nl.locale.json'),
  };

  store.dispatch(setGlobalTranslations(json));

  if (searchParams.get('locale') && json.hasOwnProperty(searchParams.get('locale'))) {
    window.localStorage.locale = searchParams.get('locale');
  }

  if (localStorage.getItem('locale')) {
    store.dispatch(updateLanguage(localStorage.getItem('locale')));
  }
}

export function localizePrivacyStatementLink(privacyStatementLink, language) {
  if (privacyStatementLink[language]) {
    return privacyStatementLink[language];
  }
  if (privacyStatementLink['en']) {
    return privacyStatementLink['en'];
  }
  return 'https://support.happy.co/hc/en-us/articles/24882368694676-Privacy-Policy';
}

export function localizeHelpLink(helpDocuments, language, type) {
  if (!helpDocuments) {
    return 'https://yuhusupport.helpdocs.io/';
  }

  if (helpDocuments[type] && helpDocuments[type][language]) {
    return helpDocuments[type][language];
  }

  if (helpDocuments[type] && helpDocuments[type]['en']) {
    return helpDocuments[type]['en'];
  }

  if (helpDocuments['default']) {
    return helpDocuments['default'][language] || helpDocuments['default']['en'];
  }

  return 'https://yuhusupport.helpdocs.io/';
}

export function localizeChequeImage(language, padDetailType) {
  const isACH = padDetailType === padDetailsTypes.ACH;
  let chequeImage =
    language === 'fr'
      ? 'https://s3.ca-central-1.amazonaws.com/prod-ca-central-1/yuhu-public-images/autopay-cheque_fr.png'
      : 'https://s3.ca-central-1.amazonaws.com/prod-ca-central-1/yuhu-public-images/autopay-cheque_en.png';

  if (isACH) {
    chequeImage =
      'https://s3.ca-central-1.amazonaws.com/prod-ca-central-1/yuhu-public-images/autopay-cheque-ach.png';
  }
  return chequeImage;
}
