import React from 'react';
import 'moment/locale/fr';
import 'moment/locale/nl';
import moment from 'moment';
import momentTZ from 'moment-timezone';
import currency from 'currency.js';
import { createNumberMask } from 'redux-form-input-masks';
import { parsePhoneNumberFromString } from 'libphonenumber-js';

import { colors } from 'helpers/ColorHelper';
import { getCurrentLocale, getTranslatedString } from 'helpers/i18n';
import { removeFalsyValuesFromObject } from 'helpers/utilities';
import { calculateRentChangePercentage } from 'helpers/numberHelpers';

export function getTimezoneSpecificDate(dateTime, timezone) {
  return momentTZ(dateTime, momentTZ.ISO_8601, true).tz(timezone);
}

export function createTimeRange() {
  // https://stackoverflow.com/questions/36125038/generate-array-of-times-as-strings-for-every-x-minutes-in-javascript
  const x = 30; //minutes interval
  let times = []; // time array
  let tt = 0; // start time
  const ap = ['AM', 'PM']; // AM-PM

  //loop to increment the time and push results in array
  for (let i = 0; tt < 24 * 60; i++) {
    const hh = Math.floor(tt / 60); // getting hours of day in 0-24 format
    const mm = tt % 60; // getting minutes of the hour in 0-55 format
    times[i] =
      ('0' + (hh === 12 || hh === 0 ? 12 : hh % 12)).slice(-2) +
      ':' +
      ('0' + mm).slice(-2) +
      ap[Math.floor(hh / 12)];
    tt = tt + x;
  }

  return times;
}

export function timeFromNow(dateTime, options = {}) {
  if (!dateTime) return null;

  if (options.timezone) {
    dateTime = getTimezoneSpecificDate(dateTime, options.timezone);
  }
  return moment(dateTime, moment.ISO_8601, true)
    .locale(options.language || 'en')
    .fromNow();
}

export function humanizeSymbol(symbol) {
  return symbol.replace(/_/g, ' ');
}

export const maxNumberLength = (maximumNumber) => (value, previousValue, allValues) => {
  return value.length <= maximumNumber
    ? onlyNumbers(value, true)
    : onlyNumbers(previousValue, true);
};

export function formatPercent(value, previousValue) {
  if (!value && value !== 0) return '';

  if (typeof value === 'number') {
    value = value.toString();
  }
  if (value[value.length - 1] === '.') {
    return value;
  }
  const onlyNums = value.replace(/[^\d.]/g, '');
  if (onlyNums.length === 0) {
    return '';
  }

  return parseFloat(onlyNums).toLocaleString() + '%';
}

export function normalizePercent(value, prevVal) {
  if (onlyNumbers(value) === prevVal) {
    return onlyNumbers(value.slice(0, value.length - 1));
  } else {
    return onlyNumbers(value);
  }
}

export const currencyMaskCents = createNumberMask({
  prefix: '$',
  decimalPlaces: 2,
  locale: 'en-US',
  allowEmpty: true,
  multiplier: 100,
});

export const currencyMask = createNumberMask({
  prefix: '$',
  decimalPlaces: 2,
  locale: 'en-US',
  allowEmpty: true,
});

export const percentMask = createNumberMask({
  prefix: '',
  suffix: '%',
  decimalPlaces: 2,
  locale: 'en-US',
  allowEmpty: true,
  multiplier: 1 / 100,
});

export const moneyToFloat = (moneyString) => {
  if (!moneyString) return;
  return Number(moneyString.replace(/[^0-9.-]+/g, ''));
};

export const formatMoney = (number) => {
  if (
    number &&
    typeof number === 'object' &&
    number.currency_iso &&
    number.cents !== undefined &&
    number.cents !== null
  ) {
    number = currency(number.cents, { fromCents: true });
  }

  const float = parseFloat(number || 0);
  if (isNaN(float)) throw Error(`Invalid argument given to formatMoney(): ${number}`);

  const locale = getCurrentLocale();
  return float.toLocaleString(locale, { style: 'currency', currency: 'USD' }).replace('US', '');
};

export function objectToQuery(obj) {
  if (!obj || typeof obj !== 'object' || Object.keys(obj).length === 0) return '';

  return Object.keys(obj).reduce((str, key, i) => {
    let delimiter, val;
    delimiter = i === 0 ? '?' : '&';
    key = encodeURIComponent(key);
    val = encodeURIComponent(obj[key]);
    return `${str}${delimiter}${key}=${val}`;
  }, '');
}

export function formatBoolean(data) {
  if (data === 'true' || data === true) {
    return 'Yes';
  } else if (data === 'false' || data === false) {
    return 'No';
  }
}

export function preventUserFromTyping(e, regex) {
  const keyCode = e.keyCode;
  const keyValue = String.fromCharCode(keyCode);
  // if user typed value does not match regex, it will prevent it
  if (!keyValue.match(regex)) e.preventDefault();
}

// formatting phone from backend for viewing
export function formatPhone(phoneNumber, withBrackets = true) {
  const parsingOutput = parsePhoneNumberFromString(phoneNumber || '');
  let nationalNumber, countryCode, firstThreeDigits, secondThreeDigits, lastFourDigits;

  if (!phoneNumber) return;

  if (phoneNumber.replace(/\s/g, '') === '+00000000000') return '+0 000-000-0000';

  if (parsingOutput && parsingOutput.nationalNumber && parsingOutput.countryCallingCode) {
    nationalNumber = parsingOutput.nationalNumber;
    firstThreeDigits = nationalNumber.substr(0, 3);
    secondThreeDigits = nationalNumber.substr(3, 3);
    lastFourDigits = nationalNumber.substr(6, 4);
    countryCode = parsingOutput.countryCallingCode;
    return `+${countryCode} ${firstThreeDigits}-${secondThreeDigits}-${lastFourDigits}`;
  }

  if (parsingOutput && phoneNumber.startsWith('+')) return parsingOutput.formatInternational();

  if (phoneNumber.length !== 10) return phoneNumber;

  if (withBrackets) {
    return `(${phoneNumber.substr(0, 3)}) ${phoneNumber.substr(3, 3)}-${phoneNumber.substr(6, 4)}`;
  }

  return `${phoneNumber.substr(0, 3)}-${phoneNumber.substr(3, 3)}-${phoneNumber.substr(6, 4)}`;
}

// normalize phone number on redux-form
export function normalizePhone(value, previousValue) {
  if (!value) {
    return value;
  }
  const onlyNums = value.replace(/[^\d]/g, '');
  if (!previousValue || value.length > previousValue.length) {
    // typing forward
    if (onlyNums.length === 3) {
      return onlyNums + '-';
    }
    if (onlyNums.length === 6) {
      return onlyNums.slice(0, 3) + '-' + onlyNums.slice(3) + '-';
    }
  }
  if (onlyNums.length <= 3) {
    return onlyNums;
  }
  if (onlyNums.length <= 6) {
    return onlyNums.slice(0, 3) + '-' + onlyNums.slice(3);
  }
  return onlyNums.slice(0, 3) + '-' + onlyNums.slice(3, 6) + '-' + onlyNums.slice(6, 10);
}

export function formatTimeForTimer(time) {
  const pad = (time, length) => {
    while (time.length < length) {
      time = '0' + time;
    }
    return time;
  };

  time = new Date(time);
  let h = Math.floor(time / 3600000);
  let m = pad(time.getMinutes().toString(), 2);
  let s = pad(time.getSeconds().toString(), 2);

  return `${h} h : ${m} m : ${s} s`;
}

export function onlyNumbers(value, stringAsOutput = false) {
  if (value === null || value === undefined || value === '') {
    return null;
  } else if (typeof value === 'number') {
    return Number(value);
  }

  if (stringAsOutput === true) {
    return value.replace(/[^0-9.]+/g, '');
  } else {
    return Number(value.replace(/[^0-9.]+/g, ''));
  }
}

export function onlyPositiveNumbers(value, stringAsOutput = false) {
  let number = onlyNumbers(value, stringAsOutput);

  if (number < 1) {
    return null;
  }

  return number;
}

export function blendTwoColours(c1, c2, value) {
  // break colours into components
  const [c1Red, c1Green, c1Blue] = c1.split(',').map((val) => parseInt(val.replace(/\D/g, ''), 10));
  const [c2Red, c2Green, c2Blue] = c2.split(',').map((val) => parseInt(val.replace(/\D/g, ''), 10));
  // assign weights
  const w1 = 1 - value / 100.0;
  const w2 = value / 100.0;
  // blend colours
  const red = (c1Red * w1 + c2Red * w2) | 0;
  const green = (c1Green * w1 + c2Green * w2) | 0;
  const blue = (c1Blue * w1 + c2Blue * w2) | 0;

  return `rgb(${red}, ${green}, ${blue})`;
}

export function blendThreeColours(c1, c2, c3, value) {
  const firstScaled = (value * 2) % 101;
  const secondScaled = Math.max(0, (value - 50) * 2);
  const firstBlend = blendTwoColours(c1, c2, firstScaled);
  return blendTwoColours(firstBlend, c3, secondScaled);
}

export function titleize(string) {
  const textNotToCapitalize = ['at', 'the'];
  if (!string) return '';

  return string.replace(/\w\S*/g, function (txt, index) {
    if (textNotToCapitalize.includes(txt) && index !== 0) return txt;
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
}

export const RenderError = ({ meta: { touched, error } }) =>
  touched && error ? (
    <p style={{ color: colors.RED, fontSize: '11px', textAlign: 'center' }}>{error}</p>
  ) : (
    false
  );

// DATES

// DEPRECATED: use toDate
// string to date
export function formatToLocalDate(dateString) {
  return moment(dateString).locale('en').local().toDate();
}

// DEPRECATED: use toFriendlyDateString
// string/date to friendly date string
export function reformatDate(date) {
  return moment(date).locale('en').format('MMM D, YYYY');
}

// Used to convert a datetime from the backend to a moment without converting timezone
// from the property's timezone to the user's computer's local time
export function toMomentIgnoreTimeZone(dateTimeString) {
  const timeZoneOffset = new Date().getTimezoneOffset();
  return moment(dateTimeString).add(timeZoneOffset, 'minutes');
}

export function reformatBirthDate(date) {
  return moment(date).locale('en').format('MMM D');
}

// used to create a date from today
export function daysFromNow(days = 0, fromDate = moment()) {
  if (!fromDate) return null;
  return moment(fromDate).add(days, 'days').toDate();
}

export function differenceInMonthsRounded(startDate, endDate) {
  return Math.ceil(differenceInMonths(startDate, endDate));
}

export const differenceIn = (units) => (startDate, endDate) => {
  if (!startDate || !endDate) return null;
  return moment(endDate).diff(moment(startDate), units, true);
};

export const differenceInMonths = differenceIn('months');
export const differenceInDays = differenceIn('days');

// used to convert date strings into date objects
export function toDate(dateString, timezone = null) {
  if (!dateString) return null;
  if (timezone) {
    dateString = getTimezoneSpecificDate(dateString, timezone);
  }
  return moment(dateString, moment.ISO_8601, true).locale('en').toDate();
}

export function toTimeString(dateTime) {
  if (!dateTime) return null;

  return moment(dateTime).locale('en').format('h:mm A');
}

// takes a date and outputs a user friendly date string
// used when printing dates on the UI in smaller containers
export function toFriendlyDateStringNoYear(date, options = {}) {
  const locale = options.language || getCurrentLocale();

  if (!date) return null;
  if (options.timezone) {
    date = getTimezoneSpecificDate(date, options.timezone);
  }

  if (locale === 'nl') {
    return titleize(
      moment(date, moment.ISO_8601, true)
        .locale(locale)
        .format(options.showTwoDigitsForDays ? 'DD MMMM' : 'D MMMM')
    );
  }

  return titleize(
    moment(date, moment.ISO_8601, true)
      .locale(locale)
      .format(options.showTwoDigitsForDays ? 'MMM DD' : 'MMM D')
  );
}

// takes a date and outputs a user friendly date string
// used when printing dates on the UI
export function toFriendlyDateString(date, options = {}) {
  const locale = options.language || getCurrentLocale();
  let format = 'MMM D, YYYY';
  if (options.fullMonth) {
    format = 'MMMM D, YYYY';
  }

  if (!date) return null;

  if (options.timezone) {
    date = getTimezoneSpecificDate(date, options.timezone);
  }

  if (locale === 'nl') {
    return titleize(moment(date, moment.ISO_8601, true).locale(locale).format('D MMMM YYYY'));
  }

  return titleize(moment(date, moment.ISO_8601, true).locale(locale).format(format));
}

// takes a date and outputs a user friendly date string
// used when printing dates on the UI

export function toFriendlyDateTimeString(datetime, options = {}) {
  let format = 'MMM D, YYYY [at] h:mma';

  if (!datetime) return null;

  if (options.language === 'fr') {
    format = 'D MMM, YYYY [à] LT';
  }
  if (options.language === 'nl') {
    format = 'D MMM, YYYY [om] LT';
  }
  if (options.timezone) {
    datetime = getTimezoneSpecificDate(datetime, options.timezone);
  }
  return moment(datetime, moment.ISO_8601, true)
    .locale(options.language || 'en')
    .format(format);
}

export function toFriendlyDateTime(datetime, options = {}) {
  let format = 'YYYY-MM-DD [at] h:mm A zz';

  if (!datetime) return null;

  if (options.language === 'fr') {
    format = 'YYYY-MM-DD [à] h:mm A zz';
  }
  if (options.language === 'nl') {
    format = 'YYYY-MM-DD [om] h:mm A zz';
  }
  if (options.timezone) {
    datetime = getTimezoneSpecificDate(datetime, options.timezone);
  }
  return moment(datetime, moment.ISO_8601, true)
    .locale(options.language || 'en')
    .format(format);
}

export function toFriendlyTimeString(datetime, options = {}) {
  let format = 'h:mm a';

  if (!datetime) return null;

  if (options.timezone) {
    datetime = getTimezoneSpecificDate(datetime, options.timezone);
  }

  if (options.language === 'fr') {
    format = 'LT';
  }

  return moment(datetime, moment.ISO_8601, true)
    .locale(options.language || 'en')
    .format(format);
}

// takes start date time and end date time
// returns "date" from "startTime" - "endTime"
// eg. Nov 1, 2020 from 1:00pm - 2:00pm
export function toFriendlySameDateTimeRange(startDateTime, endDateTime, options = {}) {
  if (!startDateTime || !endDateTime) return null;
  const endTime = toFriendlyTimeString(endDateTime, options);
  const date = toFriendlyDateString(startDateTime, options);
  const startTime = toFriendlyTimeString(startDateTime, options);

  return getTranslatedString('fromStartTimeToEndTime', {
    separator: options.separator ?? '-',
    date: date,
    startTime: startTime,
    endTime: endTime,
  });
}

// takes start date time and end date time
// and outputs a string formatted to read as a range.
// i.e start_date to end_date
export function toFriendlyDateTimeRange(startDateTime, endDateTime, options = {}) {
  if (!startDateTime || !endDateTime) return null;
  const start = toFriendlyDateTimeString(startDateTime, options);
  const end = toFriendlyDateTimeString(endDateTime, options);
  return `${start} ${getTranslatedString('to')} ${end}`;
}

export function smartToFriendlyDateTimeRange(startDateTime, endDateTime, options = {}) {
  if (!startDateTime || !endDateTime) return null;

  if (moment(startDateTime).isSame(endDateTime, 'day')) {
    return toFriendlySameDateTimeRange(startDateTime, endDateTime, options);
  }

  return toFriendlyDateTimeRange(startDateTime, endDateTime, options);
}

// takes a date and converts it into a 'YYYY-MM-DD' format string
// used when sending dates to the backend without a time component
export function toISO8601DateString(date) {
  if (!date) return null;

  return moment(date, moment.ISO_8601, true).locale('en').format('YYYY-MM-DD').toString();
}

// takes a date and converts it into a 'YYYY-MM-DD 22:10 pm' format string
// used when sending dates to the backend without a time component
export function toISO8601DateTimeString(date) {
  if (!date) return null;

  return moment(date, moment.ISO_8601, true).locale('en').format('YYYY-MM-DD[T]HH:mm').toString();
}

// takes date string and converts to moment date
// to be used for antd date picker initial value with formik
export function toMomentDate(date) {
  if (!date) return '';

  return moment(date, 'YYYY-MM-DD', getCurrentLocale());
}

// takes datetime string and converts to moment date
// to be used for antd date picker initial value with formik
export function toMomentDateTime(datetime) {
  if (!datetime) return '';

  return moment(datetime, 'YYYY-MM-DD hh:mm a', getCurrentLocale());
}

export function toDateTimeWithTimezone(datetime, timezone) {
  if (!datetime) return '';

  if (!timezone) {
    timezone = moment.tz.guess(true);

    if (!timezone) {
      timezone = 'America/Toronto';
    }
  }

  return moment(datetime).tz(timezone).format('YYYY-MM-DD @ hh:mm a z');
}

export function combineDateAndTime(dateString, timeString) {
  if (!dateString || !timeString) return null;

  let dateObj = moment(dateString, moment.ISO_8601, true);
  let dateFormatted = dateObj.locale('en').format('YYYY-MM-DD').toString();
  let timeObj = moment(timeString, ['h:m a', 'H:m']);
  let timeFormatted = timeObj.locale('en').format('HH:mm').toString();
  let result = dateFormatted + 'T' + timeFormatted;
  return result;
}

export function toEndOfDay(datetime) {
  if (!datetime) return null;

  return moment(datetime).endOf('day').toDate();
}

export function formatDateTime(date, options = {}) {
  if (!date) return '';

  options = {
    month: 'short',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    ...options,
  };

  return date.toLocaleDateString('en-us', removeFalsyValuesFromObject(options));
}

// Allows access to redux-form without having to use formValueSelectors
export const GenericComponent = ({ input }) => <span>{input.value}</span> || null;

export function calendarDateInWords(date) {
  if (!date) return '';

  return moment(date).local().locale('en').calendar();
}

export function maxDate(amount, type) {
  return moment().subtract(amount, type).toDate();
}

export function formatHoursDays(number, dayHour = 'days') {
  if (!number || number === 0) return 0;
  if (dayHour === 'hours') {
    return parseInt(number / 60, 10);
  } else {
    return parseFloat(parseFloat(number / 60 / 24, 10).toFixed(1));
  }
}

export function emptyStringToNull(value, originalValue) {
  if (typeof originalValue === 'string' && originalValue === '') {
    return null;
  }
  return value;
}

export function lossToLeaseInCents(marketRent, leasePricingRent) {
  const parsedLeaseRentInCents = parseFloat(leasePricingRent.cents); // TODO: Current back-end is returning this value as a string;
  return currency(marketRent.cents - parsedLeaseRentInCents, { fromCents: false, precision: 2 })
    .value;
}

export function formatRentChangePercentage(marketRent, leasePricingRent) {
  console.log('marketRent', marketRent);
  console.log('leasePricingRent', leasePricingRent);
  const parsedLeaseRentInCents = parseFloat(leasePricingRent.cents); // TODO: Current back-end is returning this value as a string;
  const value = ((marketRent.cents / parsedLeaseRentInCents - 1) * 100).toFixed(1);
  if (value > 0.0) {
    return `+${value}%`;
  }

  return `${value}%`;
}
