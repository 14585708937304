import React from 'react';
import { connect } from 'react-redux';
import { Field, reduxForm, FieldArray, formValueSelector, FormSection } from 'redux-form';

import IconButton from 'material-ui/IconButton';
import FlatButton from 'material-ui/FlatButton';
import { RadioButton } from 'material-ui/RadioButton';

import { MdDelete as DeleteIcon } from 'react-icons/md';

import { TextField } from 'redux-form-material-ui';
import { RadioButtonGroup } from 'redux-form-material-ui';

import { BooleanRadioButtons } from 'helpers/BooleanRadioButtons';
import { RenderError } from 'helpers/Formatter';
import * as validator from 'helpers/Validator';
import { colors } from 'helpers/ColorHelper';
import { AccentColor } from 'helpers/AccentColorHelper';

import { CSSTransitionGroup } from 'react-transition-group';
import { getTranslatedString } from 'helpers/i18n';

const ADDED_SERVICES_LIMIT = 9;

export const OtherServicesForm = ({ fields, meta, initialFields, isReviewMode }) => {
  if (Array.isArray(fields)) {
    return null;
  }

  if (initialFields && fields.length === 0) {
    fields.push({});
  }

  return (
    <div className="row">
      <CSSTransitionGroup
        transitionName="add_extra_services"
        transitionEnterTimeout={500}
        transitionLeaveTimeout={500}
      >
        {fields.map((otherServices, index) => (
          <div key={index} className="row">
            <div className="row" style={{ marginTop: '15px' }}>
              <div className="col-sm-3">
                <Field
                  name={`${otherServices}.name`}
                  type="text"
                  component={TextField}
                  hintText={getTranslatedString(
                    'landlordPortal.servicesAndUtilitiesWizardPage.other'
                  )}
                  hintStyle={{ fontSize: '14px' }}
                  validate={[validator.required]}
                  style={{ marginTop: '-50px', width: '130px' }}
                  disabled={isReviewMode}
                />
              </div>

              <div className="col-sm-4">
                <BooleanRadioButtons
                  name={`${otherServices}.is_included`}
                  style={{ width: '180px', display: 'inline-flex' }}
                  disabled={isReviewMode}
                />
              </div>

              <div className="col-sm-5">
                {!isReviewMode && (
                  <IconButton
                    style={{
                      padding: 0,
                      verticalAlign: 'text-bottom',
                      width: 'none',
                      height: 'none',
                      float: 'right',
                    }}
                    onClick={(event) => {
                      if (fields.length > 0) {
                        fields.remove(index);
                      }
                    }}
                  >
                    <DeleteIcon size={24} style={{ padding: 0 }} />
                  </IconButton>
                )}
              </div>
            </div>
          </div>
        ))}
      </CSSTransitionGroup>
      {!isReviewMode && (
        <div className="row" style={{ textAlign: 'center', marginTop: '20px' }}>
          <FlatButton
            label={getTranslatedString(
              'landlordPortal.servicesAndUtilitiesWizardPage.addExtraService'
            )}
            type="button"
            backgroundColor={fields.length >= ADDED_SERVICES_LIMIT ? 'grey' : AccentColor()}
            hoverColor="grey"
            style={{ color: 'white' }}
            onClick={() => {
              if (fields.length < ADDED_SERVICES_LIMIT) {
                fields.push({});
              }
            }}
            disabled={fields.length >= ADDED_SERVICES_LIMIT}
          />
        </div>
      )}
    </div>
  );
};

let ServicesAndUtilitiesFormFields = ({
  isReviewMode,
  show_on_site_laundry_charge_type,
  show_guest_parking_charge_type,
}) => {
  return (
    <FormSection name="services_and_utilities">
      <div style={{ color: isReviewMode ? colors.DARK_GRAY : 'black' }}>
        {!isReviewMode && (
          <h3 style={{ textAlign: 'center', marginBottom: '35px' }}>
            {' '}
            {getTranslatedString(
              'landlordPortal.servicesAndUtilitiesWizardPage.servicesAndUtilities'
            )}
          </h3>
        )}
        <div
          style={{
            marginTop: '35px',
            fontSize: isReviewMode ? '14px' : '16px',
            fontWeight: 'bolder',
            marginLeft: '15px',
          }}
        >
          {' '}
          {getTranslatedString(
            'landlordPortal.servicesAndUtilitiesWizardPage.includedServices'
          )}{' '}
        </div>

        <FormSection name="services">
          <div className="row" style={{ marginTop: '15px' }}>
            <div className="col-sm-3">
              <p>{getTranslatedString('landlordPortal.servicesAndUtilitiesWizardPage.gas')}</p>
            </div>
            <div className="col-sm-4">
              <BooleanRadioButtons
                disabled={isReviewMode}
                name={'gas.is_included'}
                style={{ width: '180px', display: 'inline-flex' }}
              />
            </div>
          </div>

          <div className="row" style={{ marginTop: '15px' }}>
            <div className="col-sm-3">
              <p>
                {getTranslatedString(
                  'landlordPortal.servicesAndUtilitiesWizardPage.airConditioning'
                )}
              </p>
            </div>
            <div className="col-sm-4">
              <BooleanRadioButtons
                disabled={isReviewMode}
                name={'air_conditioning.is_included'}
                style={{ width: '180px', display: 'inline-flex' }}
              />
            </div>
          </div>

          <div className="row" style={{ marginTop: '15px' }}>
            <div className="col-sm-3">
              <p>
                {getTranslatedString(
                  'landlordPortal.servicesAndUtilitiesWizardPage.additionalStorageSpace'
                )}
              </p>
            </div>
            <div className="col-sm-4">
              <BooleanRadioButtons
                disabled={isReviewMode}
                name={'storage_space.is_included'}
                style={{ width: '180px', display: 'inline-flex' }}
              />
            </div>
          </div>

          <div className="row" style={{ marginTop: '15px' }}>
            <div className="col-sm-3">
              <p>
                {getTranslatedString('landlordPortal.servicesAndUtilitiesWizardPage.onSiteLaundry')}
              </p>
            </div>
            <div className="col-sm-4">
              <BooleanRadioButtons
                disabled={isReviewMode}
                name={'on_site_laundry.is_included'}
                style={{ width: '180px', display: 'inline-flex' }}
              />
            </div>
            {show_on_site_laundry_charge_type && (
              <div className="col-sm-5">
                <Field name="on_site_laundry.charge_type" component={RadioButtonGroup}>
                  <RadioButton
                    value="no_charge"
                    label={getTranslatedString(
                      'landlordPortal.servicesAndUtilitiesWizardPage.noCharge'
                    )}
                    style={{ width: '85px', display: 'inline-flex' }}
                    disabled={isReviewMode}
                  />
                  <RadioButton
                    value="pay_per_use"
                    label={getTranslatedString(
                      'landlordPortal.servicesAndUtilitiesWizardPage.payPerUse'
                    )}
                    style={{ width: '115px', display: 'inline-flex' }}
                    disabled={isReviewMode}
                  />
                </Field>
                <Field
                  name="on_site_laundry.charge_type"
                  validate={[validator.required]}
                  component={RenderError}
                />
              </div>
            )}
          </div>

          <div className="row" style={{ marginTop: '15px' }}>
            <div className="col-sm-3">
              <p>
                {getTranslatedString('landlordPortal.servicesAndUtilitiesWizardPage.guestParking')}
              </p>
            </div>
            <div className="col-sm-4">
              <BooleanRadioButtons
                name={'guest_parking.is_included'}
                style={{ width: '180px', display: 'inline-flex' }}
                disabled={isReviewMode}
              />
            </div>

            {show_guest_parking_charge_type && (
              <div className="col-sm-5">
                <Field name="guest_parking.charge_type" component={RadioButtonGroup}>
                  <RadioButton
                    value="no_charge"
                    label={getTranslatedString(
                      'landlordPortal.servicesAndUtilitiesWizardPage.noCharge'
                    )}
                    style={{ width: '85px', display: 'inline-flex' }}
                    disabled={isReviewMode}
                  />
                  <RadioButton
                    value="pay_per_use"
                    label={getTranslatedString(
                      'landlordPortal.servicesAndUtilitiesWizardPage.payPerUse'
                    )}
                    style={{ width: '115px', display: 'inline-flex' }}
                    disabled={isReviewMode}
                  />
                </Field>
                <Field
                  name="guest_parking.charge_type"
                  validate={[validator.required]}
                  component={RenderError}
                />
              </div>
            )}
          </div>

          <FieldArray
            name="other"
            component={OtherServicesForm}
            key="otherServices"
            isReviewMode={isReviewMode}
          />

          <div className="row">
            <div
              style={{
                marginTop: '15px',
                fontSize: isReviewMode ? '14px' : '16px',
                fontWeight: 'bolder',
                marginLeft: '15px',
              }}
            >
              {' '}
              {getTranslatedString('landlordPortal.servicesAndUtilitiesWizardPage.servicesDetails')}
            </div>
            <div id="editable">
              <Field
                name="details"
                component={TextField}
                hintText={getTranslatedString(
                  'landlordPortal.servicesAndUtilitiesWizardPage.servicesDetailsInstructions'
                )}
                multiLine={true}
                fullWidth
                style={{ fontSize: '14px', padding: '0 15px' }}
                disabled={isReviewMode}
              />
            </div>
          </div>
        </FormSection>

        <div
          style={{
            marginTop: '35px',
            fontSize: isReviewMode ? '14px' : '16px',
            fontWeight: 'bolder',
            marginLeft: '15px',
          }}
        >
          {' '}
          {getTranslatedString('landlordPortal.servicesAndUtilitiesWizardPage.whoWillPayUtilities')}
        </div>

        <FormSection name="utilities">
          <div className="row" style={{ marginTop: '15px' }}>
            <div className="col-sm-4">
              <p>
                {getTranslatedString('landlordPortal.servicesAndUtilitiesWizardPage.electricity')}
              </p>
            </div>
            <div className="col-sm-8">
              <Field name="electricity.paid_by" component={RadioButtonGroup}>
                <RadioButton
                  value="landlord"
                  label={getTranslatedString(
                    'landlordPortal.servicesAndUtilitiesWizardPage.landlord'
                  )}
                  style={{ width: '160px', display: 'inline-flex' }}
                  disabled={isReviewMode}
                />
                <RadioButton
                  value="tenant"
                  label={getTranslatedString(
                    'landlordPortal.servicesAndUtilitiesWizardPage.resident'
                  )}
                  style={{ width: '160px', display: 'inline-flex' }}
                  disabled={isReviewMode}
                />
              </Field>
              <Field
                name="electricity.paid_by"
                validate={[validator.required]}
                component={RenderError}
              />
            </div>
          </div>

          <div className="row" style={{ marginTop: '15px' }}>
            <div className="col-sm-4">
              <p> {getTranslatedString('landlordPortal.servicesAndUtilitiesWizardPage.heat')} </p>
            </div>
            <div className="col-sm-8">
              <Field name="heat.paid_by" component={RadioButtonGroup}>
                <RadioButton
                  value="landlord"
                  label={getTranslatedString(
                    'landlordPortal.servicesAndUtilitiesWizardPage.landlord'
                  )}
                  style={{ width: '160px', display: 'inline-flex' }}
                  disabled={isReviewMode}
                />
                <RadioButton
                  value="tenant"
                  label={getTranslatedString(
                    'landlordPortal.servicesAndUtilitiesWizardPage.resident'
                  )}
                  style={{ width: '160px', display: 'inline-flex' }}
                  disabled={isReviewMode}
                />
              </Field>
              <Field name="heat.paid_by" validate={[validator.required]} component={RenderError} />
            </div>
          </div>

          <div className="row" style={{ marginTop: '15px' }}>
            <div className="col-sm-4">
              <p>{getTranslatedString('landlordPortal.servicesAndUtilitiesWizardPage.water')}</p>
            </div>
            <div className="col-sm-8">
              <Field name="water.paid_by" component={RadioButtonGroup}>
                <RadioButton
                  value="landlord"
                  label={getTranslatedString(
                    'landlordPortal.servicesAndUtilitiesWizardPage.landlord'
                  )}
                  style={{ width: '160px', display: 'inline-flex' }}
                  disabled={isReviewMode}
                />
                <RadioButton
                  value="tenant"
                  label={getTranslatedString(
                    'landlordPortal.servicesAndUtilitiesWizardPage.resident'
                  )}
                  style={{ width: '160px', display: 'inline-flex' }}
                  disabled={isReviewMode}
                />
              </Field>
              <Field name="water.paid_by" validate={[validator.required]} component={RenderError} />
            </div>
          </div>

          <div className="row">
            <div
              style={{
                marginTop: '20px',
                fontSize: isReviewMode ? '14px' : '16px',
                fontWeight: 'bolder',
                marginLeft: '15px',
              }}
            >
              {' '}
              {getTranslatedString(
                'landlordPortal.servicesAndUtilitiesWizardPage.utilitiesDetails'
              )}
            </div>
            <div id="editable">
              <Field
                name="details"
                component={TextField}
                hintText={getTranslatedString(
                  'landlordPortal.servicesAndUtilitiesWizardPage.utilitiesDetailsInstructions'
                )}
                multiLine={true}
                fullWidth
                style={{ fontSize: '14px', padding: '0 15px', marginTop: '-20px' }}
                disabled={isReviewMode}
              />
            </div>
          </div>
        </FormSection>
      </div>
    </FormSection>
  );
};

export const ServicesAndUtilitiesFields = connect((state, ownProps) => ({
  show_on_site_laundry_charge_type: formValueSelector(ownProps.formName)(
    state,
    'services_and_utilities.services.on_site_laundry.is_included'
  ),
  show_guest_parking_charge_type: formValueSelector(ownProps.formName)(
    state,
    'services_and_utilities.services.guest_parking.is_included'
  ),
}))(ServicesAndUtilitiesFormFields);

let ServicesAndUtilitiesWizardPage = ({ handleSubmit, isReviewMode, form }) => {
  return (
    <div>
      <form onSubmit={handleSubmit}>
        <ServicesAndUtilitiesFields formName={form} isReviewMode={isReviewMode} />
      </form>
    </div>
  );
};

ServicesAndUtilitiesWizardPage = reduxForm({
  destroyOnUnmount: false,
  forceUnregisterOnUnmount: true,
})(ServicesAndUtilitiesWizardPage);

export default ServicesAndUtilitiesWizardPage;
