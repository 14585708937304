import {
  apiGet,
  apiPut,
  apiPutMultipart,
  apiPost,
  apiPostMultipart,
  apiDelete,
} from 'helpers/ApiHelper';
import { objectToQuery } from 'helpers/Formatter';
import Auth from 'j-toker';

import * as notifications from './notificationActions';

import {
  toFriendlyDateString,
  toDate,
  formatPhone,
  onlyNumbers,
  toISO8601DateString,
} from 'helpers/Formatter';

import { browserHistory } from 'react-router';
import { featureEnabled } from 'helpers/ToggleBot';

export function saveApplicationId(applicationId) {
  return {
    type: 'LANDLORD_APPLICATIONS::SAVE_APPLICATION_ID',
    applicationId,
  };
}

export function getApplications(options = {}) {
  let route = '/landlords/applications';
  if (options) {
    route += objectToQuery(options);
  }
  return (dispatch) => {
    return dispatch({
      type: 'LANDLORD_APPLICATIONS::FETCH_APPLICATIONS',
      payload: apiGet(Auth.getApiUrl() + route)
        .done((response) => {
          dispatch(displayApplications(response.applications, Date.now(), options.offset || 0));
        })
        .catch((error, textStatus) => {
          dispatch(notifications.showFailure(error));
          console.error(textStatus);
        }),
    });
  };
}

export function getFilteredApplications(subset = 'all', offset = 0, options = {}) {
  return (dispatch) => {
    return dispatch({
      type: 'LANDLORD_APPLICATIONS::FETCH_APPLICATIONS',
      payload: apiPost(
        `${Auth.getApiUrl()}/landlords/filtered_applications?subset=${subset}&offset=${offset}`,
        options
      )
        .done((response) => {
          dispatch(displayApplications(response.applications, Date.now(), offset || 0));
        })
        .catch((error, textStatus) => {
          dispatch(notifications.showFailure(error));
          console.error(textStatus);
        }),
    });
  };
}

export function getApplicationStats() {
  const route = '/landlords/application_stats';
  return (dispatch) => {
    return dispatch({
      type: 'LANDLORD_APPLICATIONS::FETCH_APPLICATIONS_STATS',
      payload: apiGet(Auth.getApiUrl() + route).done((response) => {
        dispatch({
          type: 'LANDLORD_APPLICATIONS::SAVE_APPLICATIONS_STATS',
          payload: response.detailed_applications_counts,
        });
      }),
    });
  };
}

export function updateApplication(applicationID, applicationData) {
  return (dispatch) => {
    return dispatch({
      payload: apiPut(
        Auth.getApiUrl() + `/landlords/applications/${applicationID}`,
        applicationData
      )
        .done(() => {
          dispatch(notifications.showSuccess('applicationSuccessfullyUpdated'));
        })
        .catch((error, textStatus) => {
          dispatch(notifications.showFailure(error));
          console.error(textStatus);
        }),
    });
  };
}

export function saveInProgressLeaseDetails(lease_id, data = {}) {
  return (dispatch) => {
    return dispatch({
      payload: apiPut(Auth.getApiUrl() + `/landlords/save_draft_lease/${lease_id}`, data)
        .done((response) => {
          dispatch({
            type: 'LANDLORD_APPLICATIONS::SAVE_LEASE_DRAFT_DATA',
            leaseDetailsDraftData: response.draft_lease_data,
          });
        })
        .catch((error) => {
          dispatch(notifications.showFailure(error));
        }),
    });
  };
}

function receivedApplication(application) {
  application.cotenants = formatCotenants(application.cotenants);
  return {
    type: 'LANDLORD_APPLICATIONS::GET_APPLICATION',
    application,
  };
}

function formatCotenants(cotenants) {
  for (let i = 0; i < cotenants.length; i++) {
    // general details
    const formattedPhone = formatPhone(cotenants[i].general_details.phone_number);
    cotenants[i].general_details.phone_number = formattedPhone;

    let formattedBirthday = null;
    if (cotenants[i].general_details.birthday) {
      formattedBirthday = toFriendlyDateString(cotenants[i].general_details.birthday);
    }
    cotenants[i].general_details.birthday = formattedBirthday;

    // application questions
    for (let key in cotenants[i].application_questions) {
      if (key === 'reason_for_moving') {
        cotenants[i].application_questions[key] =
          cotenants[i].application_questions[key] || 'No reason provided';
      } else if (cotenants[i].application_questions[key]) {
        cotenants[i].application_questions[key] = 'yes';
      } else {
        cotenants[i].application_questions[key] = 'no';
      }
    }
  }
  return cotenants;
}

export function deleteApplicationDocument(id) {
  return (dispatch) => {
    return dispatch({
      payload: apiDelete(Auth.getApiUrl() + '/landlords/rental_application/documents/' + id)
        .done((response) => {
          dispatch(notifications.showSuccess('successfullyDeletedDocument'));
        })
        .catch((error) => {
          dispatch(notifications.showFailure(error));
          console.error('request failed', error);
        }),
    });
  };
}

export function syncApplicationWithYardi(applicationID) {
  return (dispatch) => {
    return dispatch({
      payload: apiPost(Auth.getApiUrl() + `/landlords/sync_application/${applicationID}`)
        .done((response) => {
          dispatch(notifications.showSuccess('applicationSuccessfullySynced'));
        })
        .catch((error, textStatus) => {
          dispatch(notifications.showFailure(error));
          console.error(textStatus);
        }),
    });
  };
}

export function cancelApplication(applicationID) {
  return (dispatch) => {
    return dispatch({
      payload: apiPost(Auth.getApiUrl() + `/landlords/applications/cancel/${applicationID}`)
        .done((response) => {
          return dispatch(notifications.showSuccess('successfullyCancelledApplication'));
        })
        .catch((error, textStatus) => {
          return dispatch(notifications.showFailure(error));
        }),
    });
  };
}

export function getApplication(applicationID) {
  return (dispatch) => {
    return dispatch({
      payload: apiGet(Auth.getApiUrl() + `/landlords/applications/${applicationID}`)
        .done((response) => {
          dispatch(receivedApplication(response.application));
        })
        .catch((error, textStatus) => {
          console.error(textStatus);
        }),
    });
  };
}

export function getLease(id) {
  return (dispatch, getState) => {
    return dispatch({
      payload: apiGet(Auth.getApiUrl() + `/landlords/leases/${id}?for_lease_wizard=true`)
        .done((response) => {
          dispatch(openLeaseCreator(response.lease_details));
          return response.lease_details;
        })
        .catch((error) => {
          dispatch(notifications.showFailure(error));
        }),
    });
  };
}

export function approveReference(applicantId, referenceType, referenceId) {
  return (dispatch) => {
    const referenceUrl =
      referenceType === 'landlord_information'
        ? `/landlords/landlord_references/verification/${referenceId}`
        : `/landlords/personal_references/verification/${referenceId}`;
    return dispatch({
      payload: apiPut(Auth.getApiUrl() + referenceUrl)
        .done(() => {
          return dispatch(notifications.showSuccess('referenceMarkedAsVerified'));
        })
        .catch((error, textStatus) => {
          console.error(textStatus);
        }),
    });
  };
}

export function assignUnit(applicationId, unitId, propertyId) {
  return (dispatch) => {
    return dispatch({
      payload: apiPost(Auth.getApiUrl() + `/landlords/applications/assign_unit/${applicationId}`, {
        unit_id: unitId,
        property_id: propertyId,
      })
        .done((response) => {
          dispatch(notifications.showSuccess('successfullyAssignedUnit'));
        })
        .catch((error) => {
          dispatch(notifications.showFailure(error));
          console.error('request failed', error);
          throw Error;
        }),
    });
  };
}

export function denyApplication(options) {
  return (dispatch) => {
    return dispatch({
      payload: apiPut(Auth.getApiUrl() + `/landlords/applications/deny/${options.application_id}`, {
        ...options,
      })
        .done((response) => {
          if (response.message === 'success') {
            if (options.cancel_application && options.uncancel_application) {
              dispatch(notifications.showSuccess('successfullyUncancelledApplication'));
            } else if (options.cancel_application) {
              dispatch(notifications.showSuccess('successfullyCancelledApplication'));
            } else {
              dispatch(notifications.showSuccess('successfullyDeniedApplication'));
            }
          } else {
            dispatch(
              notifications.showFailure(
                'The tenant(s) have already paid their deposit. You can no longer deny their application.'
              )
            );
          }
        })
        .catch((error, textStatus) => {
          console.error(textStatus);
        }),
    });
  };
}

export function removeApplication(applicationId) {
  return {
    type: 'LANDLORD_APPLICATIONS::REMOVE_APPLICATION',
    applicationId,
  };
}

export function displayApplications(response, timestamp, offset) {
  return {
    type: 'LANDLORD_APPLICATIONS::SHOW_APPLICATIONS',
    applications: response,
    timestamp,
    offset,
  };
}

export function openOptionsMenu(applicationId, anchorEl) {
  return {
    type: 'LANDLORD_APPLICATIONS::SHOW_OPTIONS_MENU',
    applicationId,
    anchorEl,
    shouldShow: true,
  };
}

export function closeOptionsMenu() {
  return {
    type: 'LANDLORD_APPLICATIONS::SHOW_OPTIONS_MENU',
    applicationId: null,
    anchorEl: null,
    shouldShow: false,
  };
}

export function openDenyApplication() {
  return {
    type: 'LANDLORD_APPLICATIONS::SHOW_DENY_APPLICATION',
    shouldShow: true,
  };
}

export function closeDenyApplication() {
  return {
    type: 'LANDLORD_APPLICATIONS::SHOW_DENY_APPLICATION',
    shouldShow: false,
  };
}

export function openLeaseCreator(leaseDetails) {
  if (leaseDetails.lease_start_date)
    leaseDetails.lease_start_date = toDate(leaseDetails.lease_start_date);
  if (leaseDetails.lease_end_date)
    leaseDetails.lease_end_date = toDate(leaseDetails.lease_end_date);
  if (leaseDetails.lease_move_in_date)
    leaseDetails.lease_move_in_date = toDate(leaseDetails.lease_move_in_date);
  if (leaseDetails.early_move_in_date)
    leaseDetails.early_move_in_date = toDate(leaseDetails.early_move_in_date);
  if (leaseDetails.rental_incentives && leaseDetails.rental_incentives.length) {
    leaseDetails.rental_incentives = leaseDetails.rental_incentives.map((incentive) => ({
      frequency: incentive.frequency,
      incentive_amount: incentive.incentive_amount,
      effective_date: toDate(incentive.effective_date),
      id: incentive.id,
      description: incentive.description,
      incentive_id: incentive.incentive_id,
    }));
  }

  leaseDetails.is_in_person_signing = leaseDetails.is_in_person_signing || false;

  if (
    leaseDetails.renters_insurance_required === null ||
    leaseDetails.renters_insurance_required === undefined
  ) {
    leaseDetails.renters_insurance_required = true;
  }

  if (
    leaseDetails.create_prorated_charge === null ||
    leaseDetails.create_prorated_charge === undefined
  ) {
    leaseDetails.create_prorated_charge = true;
  }

  return {
    type: 'LANDLORD_APPLICATIONS::SHOW_LEASE_CREATOR',
    leaseDetails,
  };
}

export function openLeaseInPersonOverwriteCreator(document_uuid, signer_uuid, document_type) {
  return (dispatch, getState) => {
    return dispatch({
      payload: apiPut(
        Auth.getApiUrl() +
          `/document_signing/signers/${signer_uuid}/landlord_internal_documents/${document_uuid}/in_person_overwrite`
      )
        .done((response) => {
          dispatch(
            displayDocumentToSign({
              document_uuid: document_uuid,
              access_token: response.access_token,
              document_type: document_type,
            })
          );
          browserHistory.push('/companies/overwrite_in_person_lease');
        })
        .catch((error, textStatus) => {
          console.error(textStatus);
        }),
    });
  };
}

export function openScheduleAForm() {
  return {
    type: 'LANDLORD_APPLICATIONS::SHOW_SCHEDULE_A_FORM',
    shouldShow: true,
  };
}

export function closeScheduleAForm() {
  return {
    type: 'LANDLORD_APPLICATIONS::SHOW_SCHEDULE_A_FORM',
    shouldShow: false,
  };
}

export function displayScheduleAs(scheduleAs) {
  return {
    type: 'LANDLORD_APPLICATIONS::SHOW_SCHEDULE_AS',
    scheduleAs,
  };
}

export function displayWelcomePackages(welcomePackages) {
  return {
    type: 'LANDLORD_APPLICATIONS::SHOW_WELCOME_PACKAGES',
    welcomePackages,
  };
}

export function getScheduleAs(leaseId) {
  return (dispatch) => {
    return dispatch({
      payload: apiGet(Auth.getApiUrl() + `/landlords/leases/${leaseId}/schedule_as`)
        .done((response) => {
          dispatch(displayScheduleAs(response));
        })
        .catch((error, textStatus) => {
          console.error(textStatus);
        }),
    });
  };
}

export function getWelcomePackages() {
  return (dispatch) => {
    return dispatch({
      payload: apiGet(Auth.getApiUrl() + '/landlords/welcome_packages')
        .done((response) => {
          dispatch(displayWelcomePackages(response));
        })
        .catch((error, textStatus) => {
          console.error(textStatus);
        }),
    });
  };
}

export function denyLease(leaseId, denialReason) {
  return (dispatch) => {
    return dispatch({
      type: 'LANDLORD_APPLICATIONS::POST_APPLICATION',
      payload: apiPost(Auth.getApiUrl() + `/landlords/leases/deny_created_lease/${leaseId}`, {
        lease_denial_reason: denialReason,
      }).done((response) => {
        return response;
      }),
    }).catch((error) => {
      dispatch(notifications.showFailure(error));
      console.error('request failed', error);
    });
  };
}

export function postLease(data, isModeEdit) {
  const notificationTimeout = 1000;
  let data_clone = JSON.parse(JSON.stringify(data)); // needed so it does not change redux form data
  let formData = new FormData();

  data_clone.unit_id = data_clone.unit_selected ? data_clone.unit_selected.value : null;
  data_clone.unit_type_id = data_clone.unit_type_selected
    ? data_clone.unit_type_selected.value
    : null;
  data_clone.property_id = data_clone.property.value;
  data_clone.deposit_amount = onlyNumbers(data_clone.deposit_amount);
  data_clone.key_deposit = onlyNumbers(data_clone.key_deposit);
  data_clone.monthly_parking_fee = onlyNumbers(data_clone.monthly_parking_fee);
  data_clone.monthly_rent = onlyNumbers(data_clone.monthly_rent);
  data_clone.lease_start_date = toISO8601DateString(data_clone.lease_start_date);
  data_clone.lease_end_date = toISO8601DateString(data_clone.lease_end_date);
  data_clone.cotenants = JSON.stringify(data_clone.cotenants);
  data_clone.services_and_utilities = JSON.stringify(data_clone.services_and_utilities);
  data_clone.lease_items = JSON.stringify(data_clone.lease_items);
  data_clone.collect_first_months_with_deposit = JSON.stringify(
    data_clone.collect_first_months_with_deposit
  );
  data_clone.renters_insurance_required = JSON.stringify(data_clone.renters_insurance_required);
  data_clone.create_prorated_charge = JSON.stringify(data_clone.create_prorated_charge);

  if (data_clone.early_move_in_date) {
    data_clone.early_move_in_date = toISO8601DateString(data_clone.early_move_in_date);
  }

  if (data_clone.address) {
    data_clone.address = JSON.stringify(data_clone.address);
  }

  for (let key in data_clone) {
    if (key === 'schedule_a_file' && data[key]) {
      formData.append(key, data[key][0]);
    } else if (data_clone[key] instanceof Object) {
      formData.append(key, JSON.stringify(data_clone[key]));
    } else if (data_clone[key] || data_clone[key] === 0) {
      formData.append(key, data_clone[key]);
    }
  }

  return (dispatch) => {
    return dispatch({
      type: 'LANDLORD_APPLICATIONS::POST_APPLICATION',
      payload: apiPostMultipart(Auth.getApiUrl() + '/landlords/leases', formData).done(
        (response) => {
          if (featureEnabled('custom_lease_creator') && response.data) {
            dispatch({
              type: 'LANDLORD_APPLICATIONS::SET_CLAIM_URL',
              payload: response.data.attributes.callback_url,
            });
          } else {
            browserHistory.push('/companies/applications');
            if (isModeEdit) {
              dispatch(notifications.showSuccess('successfullyUpdatedLease'));
            } else {
              dispatch(notifications.showSuccess('successfullyCreatedLease'));
            }
            setTimeout(() => {
              dispatch(notifications.hideLoader());
            }, notificationTimeout);
          }
        }
      ),
    }).catch((error) => {
      browserHistory.push('/companies/applications');
      dispatch(notifications.showFailure(error));
      setTimeout(() => {
        dispatch(notifications.hideLoader());
      }, notificationTimeout);
      console.error('request failed', error);
    });
  };
}

export function showCreateLeaseSuccess() {
  return (dispatch) => {
    browserHistory.push('/companies/applications');
    return dispatch(notifications.showSuccess('successfullyCreatedLease'));
  };
}

export function clearClaimUrl() {
  return {
    type: 'LANDLORD_APPLICATIONS::SET_CLAIM_URL',
    payload: null,
  };
}

export function openInviteLeadDialog(applicationId = null) {
  return {
    type: 'LANDLORD_APPLICATIONS::SHOW_INVITE_LEAD_DIALOG',
    shouldShow: true,
    transferApplication: applicationId,
  };
}

export function closeInviteLeadDialog() {
  return {
    type: 'LANDLORD_APPLICATIONS::SHOW_INVITE_LEAD_DIALOG',
    shouldShow: false,
    transferApplication: null,
  };
}

export function chargeLeadCreditCard(application_id) {
  return (dispatch) => {
    return dispatch({
      payload: apiPost(
        Auth.getApiUrl() + `/landlords/applications/${application_id}/charge_deposit`
      )
        .done((response) => {
          dispatch(notifications.showSuccess('successfullyChargedApplication'));
        })
        .catch((error) => {
          dispatch(notifications.showFailure(error));
          console.error('request failed', error);
          throw Error;
        }),
    });
  };
}

export function remindApplicants(application_id) {
  return (dispatch) => {
    return dispatch({
      type: 'REMIND_APPLICANTS',
      payload: apiPost(Auth.getApiUrl() + `/landlords/remind_applicants/${application_id}`)
        .done((response) => {
          dispatch(notifications.showSuccess('successfullyRemindedLeads'));
          dispatch(getApplications());
        })
        .catch((error) => {
          dispatch(notifications.showFailure(error));
          console.error('request failed', error);
        }),
    });
  };
}

export function inviteLead(data, applicationId) {
  let data_clone = JSON.parse(JSON.stringify(data));
  data_clone['property_id'] = data_clone['property']['value'];
  data_clone['birthday'] = data_clone['dob'];

  if (data_clone.lead_card && data_clone.lead_card.value) {
    data_clone.lead_card_id = data_clone.lead_card.value;
    data_clone.lead_card = null;
  }

  if (data_clone['unit_selected'] && data_clone['unit_selected']['value'] !== 'None') {
    data_clone['unit'] = data_clone['unit_selected']['value'];
  }
  if (data_clone['unit_type_selected']) {
    data_clone['unit_type_id'] = data_clone['unit_type_selected']['value'];
  }
  if (!Array.isArray(data_clone['desired_unit'])) {
    data_clone['desired_unit'] = [data_clone['desired_unit']];
  }
  let invitationPayload = () => {
    return apiPost(Auth.getApiUrl() + '/landlords/invite_lead/', data_clone);
  };
  if (applicationId) {
    invitationPayload = () => {
      return apiPut(
        Auth.getApiUrl() + `/landlords/applications/transfer_application/${applicationId}`,
        data_clone
      );
    };
  }

  return (dispatch) => {
    return dispatch({
      type: 'INVITE_LEAD',
      payload: invitationPayload()
        .done((response) => {
          dispatch(notifications.showSuccess('successfullyInvitedLead'));
          dispatch(getApplications());
        })
        .catch((error) => {
          dispatch(notifications.showFailure(error));
          console.error('request failed', error);
        }),
    });
  };
}

export function submitReferralDetails(formData, property_uuid, referrer_id) {
  return (dispatch) => {
    return dispatch({
      payload: apiPost(
        Auth.getApiUrl() + `/properties/${property_uuid}/referrals/${referrer_id}`,
        formData
      )
        .done((response) => {
          dispatch(notifications.showSuccess('thankYouForYourInterestWeWillBeInTouchShortly'));
        })
        .catch((error) => {
          dispatch(notifications.showFailure(error));
          console.error('request failed', error);
        }),
    });
  };
}

export function runCreditCheck(leadId) {
  return (dispatch) => {
    return dispatch({
      payload: apiPost(Auth.getApiUrl() + `/landlords/credit_check/${leadId}`)
        .done((response) => {
          dispatch(notifications.showSuccess('creditCheckInProgress'));
        })
        .catch((error) => {
          window.Rollbar.error('Run Credit Check Failed', error);
          console.error('request failed', error);
          dispatch(notifications.showFailure(error));
          return false;
        }),
    });
  };
}

export function runSpecificCreditCheck(application_id, lead_id, credit_check_type, sin_number) {
  return (dispatch) => {
    return dispatch({
      payload: apiPost(Auth.getApiUrl() + `/landlords/specific_credit_check/`, {
        application_id,
        lead_id,
        credit_check_type,
        sin_number,
      })
        .done((__response) => {
          dispatch(notifications.showSuccess('creditCheckInProgress'));
        })
        .catch((error) => {
          window.Rollbar.error('Run Credit Check Failed', error);
          console.error('request failed', error);
          dispatch(notifications.showFailure(error));
          return false;
        }),
    });
  };
}

export function getCreditReportStatus(leadId) {
  return (dispatch) => {
    return dispatch({
      payload: apiGet(Auth.getApiUrl() + `/landlords/credit_check/${leadId}`)
        .done((response) => {
          return response.status;
        })
        .catch((error) => {
          window.Rollbar.error('Retrieve Credit Check Report Failed', error);
          dispatch(notifications.showFailure(error));
          console.error('request failed', error);
        }),
    });
  };
}

export function displayDocumentToSign(documentToSign) {
  return {
    type: 'LANDLORD_APPLICATIONS::DISPLAY_DOCUMENT',
    documentToSign,
  };
}

export function goToPage(pageNumber) {
  return {
    type: 'LANDLORD_APPLICATIONS::GO_TO_PAGE',
    pageNumber,
  };
}

export function nextPage() {
  return {
    type: 'LANDLORD_APPLICATIONS::NEXT_PAGE',
  };
}

export function previousPage() {
  return {
    type: 'LANDLORD_APPLICATIONS::PREVIOUS_PAGE',
  };
}

export function updateApplicant(applicationId, applicantId, applicantData) {
  return async (dispatch) => {
    return dispatch({
      payload: apiPut(
        Auth.getApiUrl() + `/landlords/applications/${applicationId}/applicants/${applicantId}`,
        applicantData
      )
        .done(() => {
          dispatch(notifications.showSuccess('applicantSuccessfullyUpdated'));
        })
        .catch((error, textStatus) => {
          console.error(textStatus);
        }),
    });
  };
}

export function createOrUpdateApplicant(applicationId, applicantId, applicantData) {
  let successVerbiage = 'Applicant Successfully Added';
  if (applicantId) {
    successVerbiage = 'Applicant Successfully Updated';
  }

  return async (dispatch) => {
    return dispatch({
      payload: applicantPayload(applicationId, applicantId, applicantData)
        .done(() => {
          dispatch(notifications.showSuccess(successVerbiage));
        })
        .catch((error, textStatus) => {
          console.error(textStatus);
          if (
            error.responseJSON &&
            error.responseJSON.error_code === 'cotenant_email_must_be_unique'
          ) {
            dispatch(notifications.showFailure('coapplicantsMustHaveUniqueEmails'));
            return;
          }
          dispatch(notifications.showFailure(error));
        }),
    });
  };
}

function applicantPayload(applicationId, applicantId, applicantData) {
  if (applicantId) {
    const multiPartFormData = new FormData();
    if (!!applicantData['proofOfIncome'])
      multiPartFormData.append('proof_of_income', applicantData['proofOfIncome'][0]);
    if (!!applicantData['idProofType'])
      multiPartFormData.append('id_proof_type', applicantData['idProofType']);
    if (!!applicantData['proofOfId'])
      multiPartFormData.append('proof_of_id', applicantData['proofOfId'][0]);
    if (!!applicantData['incomeProofType'])
      multiPartFormData.append('income_proof_type', applicantData['incomeProofType']);
    const data = JSON.stringify(applicantData);
    multiPartFormData.append('data', data);

    return apiPutMultipart(
      Auth.getApiUrl() + `/landlords/applications/${applicationId}/applicants/${applicantId}`,
      multiPartFormData
    );
  } else {
    return apiPost(Auth.getApiUrl() + `/landlords/applications/${applicationId}/applicants`, {
      dob: applicantData['dob'],
      first_name: applicantData['first_name'],
      last_name: applicantData['last_name'],
      phone_number: applicantData['phone_number'],
      is_guarantor: applicantData['is_guarantor'],
      email: applicantData['email'],
      confirmed_same_resident: applicantData['confirmed_same_resident'],
    });
  }
}

export function createOrUpdateTenant(leaseID, tenantId, applicantData) {
  let successVerbiage = 'tenantSuccessfullyAdded';
  if (tenantId) {
    successVerbiage = 'tenantSuccessfullyUpdated';
  }

  return async (dispatch) => {
    return dispatch({
      payload: tenantPayload(leaseID, tenantId, applicantData)
        .done(() => {
          dispatch(notifications.showSuccess(successVerbiage));
        })
        .catch((error, textStatus) => {
          console.error(textStatus);
          if (error.responseJSON.error_code === 'cotenant_email_must_be_unique') {
            dispatch(notifications.showFailure('coapplicantsMustHaveUniqueEmails'));
            return;
          }
          dispatch(notifications.showFailure(error));
        }),
    });
  };
}

function tenantPayload(leaseID, tenantID, applicantData) {
  if (tenantID) {
    return apiPut(
      Auth.getApiUrl() + `/landlords/leases/${leaseID}/cotenants/${tenantID}`,
      applicantData
    );
  } else {
    return apiPost(Auth.getApiUrl() + `/landlords/leases/${leaseID}/cotenants`, applicantData);
  }
}

export function deleteApplicant(applicationId, applicantId) {
  return async (dispatch) => {
    return dispatch({
      payload: apiDelete(
        Auth.getApiUrl() + `/landlords/applications/${applicationId}/applicants/${applicantId}`
      )
        .done(() => {
          dispatch(notifications.showSuccess('successfullyDeletedApplicant'));
        })
        .catch((error, textStatus) => {
          console.error(textStatus);
        }),
    });
  };
}

export function skipDepositPayment(application_id) {
  return (dispatch) => {
    return dispatch({
      payload: apiPost(Auth.getApiUrl() + `/landlords/applications/${application_id}/skip_charge`)
        .done((response) => {
          dispatch(notifications.showSuccess('successfullySkippedDepositPayment'));
        })
        .catch((error) => {
          dispatch(notifications.showFailure('DepositCouldNotBeBypassed'));
          console.error('request failed', error);
          throw Error;
        }),
    });
  };
}

export function remindRenewalSignatures(leaseId) {
  return (dispatch) => {
    return dispatch({
      type: 'REMIND_RENEWAL_SIGNATURES',
      payload: apiPost(Auth.getApiUrl() + `/landlords/remind_renewal_signatures/${leaseId}`),
    }).done(() => {
      dispatch(notifications.showSuccess('landlordPortal.remindRenewalSignaturesSuccess'));
    });
  };
}

export const DOCUMENT_TEMPLATES_FOR_PROPERTY =
  'LANDLORD_APPLICATIONS::DOCUMENT_TEMPLATES_FOR_PROPERTY';
