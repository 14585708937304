export const noticeTemplateFormModes = {
  CREATE: 0,
  UPDATE: 1,
};

export const userTypes = {
  AGENT: 'Agent',
  MAINTENANCE: 'Maintenance',
  Lead: 'Lead',
};

const constants = {
  referrals: {
    APPLYING: 'applying',
    CONVERTED: 'converted',
    COMPLETED: 'completed',
    UNSUCCESFUL: 'unsuccesful',
    IN_CONTACT: 'in_contact',
    DENIED: 'denied',
  },
  applications: {
    PAYMENT_RECEIVED: 'Payment Received',
    APPLICATION_DENIED: 'Application Denied',
  },
  userTypes,
};
export default constants;

// Available Languages
export const languageMapping = {
  en: 'English',
  fr: 'Français',
  nl: 'Nederlands',
};

export const padDetailsTypes = {
  EFT: 0,
  ACH: 1,
};

export const fieldsPrototypes = {
  TextField: 'ApplicationTemplates::Prototypes::TextField',
  TextAreaField: 'ApplicationTemplates::Prototypes::TextAreaField',
  CheckBoxField: 'ApplicationTemplates::Prototypes::CheckBoxField',
  CountryField: 'ApplicationTemplates::Prototypes::CountryField',
  FileUploadField: 'ApplicationTemplates::Prototypes::FileUploadField',
  SelectField: 'ApplicationTemplates::Prototypes::SelectField',
  EmailField: 'ApplicationTemplates::Prototypes::EmailField',
  PhoneField: 'ApplicationTemplates::Prototypes::PhoneField',
  DateField: 'ApplicationTemplates::Prototypes::DateField',
  RadioButtonField: 'ApplicationTemplates::Prototypes::RadioButtonField',
  CurrencyField: 'ApplicationTemplates::Prototypes::CurrencyField',
  IncomeField: 'ApplicationTemplates::Prototypes::IncomeField',
  MultipleChoiceSingleField: 'ApplicationTemplates::Prototypes::MultipleChoiceSingleField',
  MultipleChoiceMultField: 'ApplicationTemplates::Prototypes::MultipleChoiceMultField',
  QuestionField: 'ApplicationTemplates::Prototypes::QuestionField',
  NumberField: 'ApplicationTemplates::Prototypes::NumberField',
  WeightField: 'ApplicationTemplates::Prototypes::WeightField',
  UnitTypeField: 'ApplicationTemplates::Prototypes::UnitTypeField',
  TrafficSourceField: 'ApplicationTemplates::Prototypes::TrafficSourceField',
};

export const applicationTemplateFormTypes = {
  SingleInstanceFormType: 'single_instance_form',
  MultiInstanceFormType: 'multi_instance_form',
  CoapplicantInvitationFormType: 'coapplicant_invitation_form_type',
  GuarantorInvitationFormType: 'guarantor_invitation_form_type',
};
