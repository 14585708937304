import Auth from 'j-toker';
import { QueryCache, QueryClient } from '@tanstack/react-query';
import isEqual from 'lodash/isEqual';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchInterval: false,
    },
  },
  queryCache: new QueryCache({
    onError(error, query) {
      if (isEqual(query.queryKey, ['contact_portal', 'lead'])) {
        if (error.status === 401 && Auth.user?.signedIn) {
          localStorage.removeItem('authHeaders');
          localStorage.removeItem('currentConfigName');
          queryClient.invalidateQueries({ refetchType: 'none' });

          if (window.location.href.startsWith('/companies')) {
            window.location.href = '/companies';
          } else {
            window.location.href = '/';
          }
        }
      }
    },
  }),
});

export default queryClient;
