import {
  apiGet,
  apiPost,
  apiPut,
  apiDelete,
  apiPostMultipart,
  apiPutMultipart,
} from 'helpers/ApiHelper';
import Auth from 'j-toker';
import { browserHistory } from 'react-router';
import * as notifications from './notificationActions';
import * as formatter from 'helpers/Formatter';
import deepDiff from 'return-deep-diff';
import { setAccentColor, setLandlordLogo, setGoogleTagId } from './userTypesActions';
import { destroy } from 'redux-form';
import { objectToQuery } from 'helpers/Formatter';
import * as notificationActions from './notificationActions';
import { toFriendlyTimeString } from '../helpers/Formatter';

export function updatePropertyAvailability(formData) {
  return (dispatch) => {
    return dispatch({
      payload: apiPut(Auth.getApiUrl() + `/landlords/properties/availability`, formData)
        .done((response) => {
          dispatch(getProperties());
          dispatch(notifications.showSuccess(response.message));
        })
        .catch((error, textStatus) => {
          dispatch(notifications.showFailure(error));
          console.error(textStatus);
        }),
    });
  };
}

export function showProperties(properties) {
  properties.forEach((propertyDetails) => {
    if (!propertyDetails.availability) {
      return;
    }
    propertyDetails.availability.monday.availability =
      propertyDetails.availability.monday.availability === 'true';
    propertyDetails.availability.tuesday.availability =
      propertyDetails.availability.tuesday.availability === 'true';
    propertyDetails.availability.wednesday.availability =
      propertyDetails.availability.wednesday.availability === 'true';
    propertyDetails.availability.thursday.availability =
      propertyDetails.availability.thursday.availability === 'true';
    propertyDetails.availability.friday.availability =
      propertyDetails.availability.friday.availability === 'true';
    propertyDetails.availability.saturday.availability =
      propertyDetails.availability.saturday.availability === 'true';
    propertyDetails.availability.sunday.availability =
      propertyDetails.availability.sunday.availability === 'true';
  });
  return {
    type: 'LANDLORD_PROPERTIES::SHOW_PROPERTIES',
    properties,
  };
}

export function showMarketRent(marketRent) {
  return {
    type: 'LANDLORD_PROPERTIES::SHOW_MARKET_RENT',
    marketRent,
  };
}

export function showUnits(units) {
  return {
    type: 'LANDLORD_PROPERTIES::SHOW_UNITS',
    units,
  };
}

export function storeRooms(rooms) {
  return {
    type: 'LANDLORD_PROPERTIES::STORE_ROOMS',
    rooms,
  };
}

export function showPortfolios(response) {
  return {
    type: 'LANDLORD_PROPERTIES::SHOW_PORTFOLIOS',
    portfolios: response,
  };
}

export function showAccounts(accounts) {
  return {
    type: 'PROPERTY_ACCOUNTS::SHOW_ACCOUNTS',
    accounts,
  };
}

export function showProperty(property) {
  return {
    type: 'LANDLORD_PROPERTIES::SHOW_PROPERTY',
    property,
  };
}

export function receiveAgents(agents) {
  return {
    type: 'LANDLORD_PROPERTIES::GET_AGENTS',
    agents,
  };
}

export function receiveOwners(owners) {
  return {
    type: 'LANDLORD_PROPERTIES::GET_OWNERS',
    owners,
  };
}

export function showCreatingProperty() {
  return {
    type: 'LANDLORD_PROPERTIES::SHOW_CREATING_PROPERTY',
  };
}

export function showCreatingPropertyDone() {
  return {
    type: 'LANDLORD_PROPERTIES::SHOW_CREATING_PROPERTY_DONE',
  };
}

function prepPropertyParams(data) {
  let data_clone = JSON.parse(JSON.stringify(data)); // needed so it does not change redux form data
  let formData = new FormData();
  for (let key in data_clone) {
    if (key === 'n1_or_n2') {
      formData.append('use_n1', data_clone[key] === 'n1');
      formData.append('use_n2', data_clone[key] === 'n2');
    } else if (key === 'selected_account') {
      formData.append('account_id', data_clone[key].value);
    } else if (key === 'contact_cards') {
      data_clone[key].forEach((contactCard) => {
        if (contactCard.business_hours_start && contactCard.business_hours_end) {
          contactCard.business_hours_start = formatter.toISO8601DateTimeString(
            contactCard.business_hours_start
          );
          contactCard.business_hours_end = formatter.toISO8601DateTimeString(
            contactCard.business_hours_end
          );
        }
      });
      formData.append(key, JSON.stringify(data_clone[key]));
    } else if (key === 'leasing_agents') {
      const agents = data_clone[key].map((agent) => agent.value);
      formData.append(key, JSON.stringify(agents));
    } else if (key === 'property_photo') {
      formData.append('avatar', data[key][0]);
    } else if (key === 'hero_image') {
      formData.append('hero_image', data[key][0]);
    } else if (key === 'amenities') {
      // unpack amenities
      for (let amenity_key in data_clone[key]) {
        if (data_clone[key].hasOwnProperty(amenity_key)) {
          formData.append(amenity_key, data_clone[key][amenity_key]);
        }
      }
    } else if (key !== 'floor_plans' && data_clone[key] instanceof Object) {
      formData.append(key, JSON.stringify(data_clone[key]));
    } else if (data_clone[key] === 'true') {
      formData.append(key, true);
    } else if (data_clone[key] === 'false') {
      formData.append(key, false);
    } else if (key !== 'floor_plans') {
      formData.append(key, data_clone[key]);
    }
  }

  if (data.floor_plans && data.floor_plans.length) {
    let imageKeyCounter = 0; // used to link item to image
    // add all images and link the floorplan item to the image
    data.floor_plans.forEach((floorPlan) => {
      if (floorPlan.photo) {
        // naming on the key is inspectionItemId-photo. Example: photo-10
        const photoKey = `photo-${imageKeyCounter}`;

        formData.append(photoKey, floorPlan.photo[0], floorPlan.photo[0].name);
        floorPlan.photo_key = photoKey;
        imageKeyCounter++;
      }
    });

    // add the data for each floor plan
    formData.append('floor_plans', JSON.stringify(data.floor_plans));
  }

  return formData;
}

export function createProperty(data) {
  return (dispatch) => {
    const formData = prepPropertyParams(data);
    return dispatch({
      payload: apiPostMultipart(Auth.getApiUrl() + `/landlords/properties`, formData)
        .then((response) => {
          browserHistory.push('/companies/properties');
          dispatch(notifications.showSuccess('propertySuccessfullyCreated'));
          setTimeout(() => {
            dispatch(notifications.hideLoader());
          }, 2000);
        })
        .catch((error) => {
          console.error('request failed', error);
          browserHistory.push('/companies/properties');
          dispatch(notifications.showFailure(error));
          setTimeout(() => {
            dispatch(notifications.hideLoader());
          }, 2000);
        }),
    });
  };
}

function itemsToRemove(initialValues, diffValues) {
  // Determine if we should delete any units
  const initialValuesIds = initialValues.map((value) => value.id);
  const updatedValuesIds = diffValues.map((value) => value.id);
  const valuesToRemove = initialValuesIds.filter((id) => updatedValuesIds.indexOf(id) < 0);

  return valuesToRemove;
}

export function updateProperty(formData, initialValues) {
  const diff = deepDiff(initialValues, formData, true);

  // Don't bother sending a request if nothing has changed
  if (!diff || !Object.keys(diff).length) return;

  if (diff.units && Array.isArray(diff.units)) {
    const unitsToDestroy = itemsToRemove(initialValues.units, diff.units);
    if (unitsToDestroy.length) {
      diff['units_to_destroy'] = JSON.stringify(unitsToDestroy);
    }
  }

  if (diff.rooms && Array.isArray(diff.rooms)) {
    const roomsToDestroy = itemsToRemove(initialValues.rooms, diff.rooms);
    if (roomsToDestroy.length) {
      diff['rooms_to_destroy'] = JSON.stringify(roomsToDestroy);
    }
  }

  if (diff.ancillary_items && Array.isArray(diff.ancillary_items)) {
    const itemsToDestroy = itemsToRemove(initialValues.ancillary_items, diff.ancillary_items);
    if (itemsToDestroy.length) {
      diff['items_to_destroy'] = JSON.stringify(itemsToDestroy);
    }
  }

  if (diff.contact_cards && Array.isArray(diff.contact_cards)) {
    const contactCardsToDestroy = itemsToRemove(initialValues.contact_cards, diff.contact_cards);
    if (contactCardsToDestroy.length) {
      diff['contact_cards_to_destroy'] = JSON.stringify(contactCardsToDestroy);
    }
  }

  if (diff.floor_plans && Array.isArray(diff.floor_plans)) {
    const floorPlansToDestroy = itemsToRemove(initialValues.floor_plans, diff.floor_plans);
    if (floorPlansToDestroy.length) {
      diff['floor_plans_to_destroy'] = JSON.stringify(floorPlansToDestroy);
    }
  }

  if (diff.owners && Array.isArray(diff.owners)) {
    const ownersToRemove = itemsToRemove(initialValues.owners, diff.owners);
    if (ownersToRemove.length) {
      diff['owners_to_remove'] = JSON.stringify(ownersToRemove);
    }
  }

  return (dispatch, getState) => {
    const formData = prepPropertyParams(diff);
    const property_id = getState().landlordProperties.property.id;
    dispatch(destroy('add_property'));

    return dispatch({
      payload: apiPostMultipart(
        Auth.getApiUrl() + `/landlords/update_property/${property_id}`,
        formData
      )
        .then((response) => {
          dispatch(getProperty(property_id));
          return dispatch(notifications.showSuccess('propertySuccessfullyUpdated'));
        })
        .catch((error) => {
          console.error('request failed', error);
          browserHistory.push('/companies/properties');
          dispatch(notifications.showFailure(error));
          setTimeout(() => {
            dispatch(notifications.hideLoader());
          }, 2000);
        }),
    });
  };
}

export function assignAccount(account_params) {
  return (dispatch) => {
    return dispatch({
      payload: apiPost(Auth.getApiUrl() + '/landlords/assign_account', account_params)
        .done((response) => {
          dispatch(getProperties());
          dispatch(getPortfolios());
          dispatch(getAccounts());
          dispatch(notifications.showSuccess('propertySuccessfullyAssignedToBankAccount'));
        })
        .catch((error, textStatus) => {
          dispatch(notifications.showFailure(error));
          console.error(textStatus);
        }),
    });
  };
}

export function assignPortfolio(portfolio_name, portfolio_id, property_id) {
  portfolio_id = portfolio_id === 'None' ? null : portfolio_id;
  const params = { portfolio_name, portfolio_id };
  return (dispatch) => {
    return dispatch({
      payload: apiPost(Auth.getApiUrl() + `/landlords/properties/${property_id}/portfolios`, params)
        .done((response) => {
          dispatch(getProperties());
          dispatch(getPortfolios());
          dispatch(getAccounts());
          dispatch(notifications.showSuccess('propertySuccessfullyAssignedToPortfolio'));
        })
        .catch((error, textStatus) => {
          dispatch(notifications.showFailure(error));
          console.error(textStatus);
        }),
    });
  };
}

export function updatePropertyInvitable(property_id) {
  return (dispatch) => {
    return dispatch({
      payload: apiPut(Auth.getApiUrl() + `/landlords/properties/${property_id}/invitable`)
        .done((response) => {
          dispatch(getProperties());
          dispatch(notifications.showSuccess('successfullyUpdated'));
        })
        .catch((error, textStatus) => {
          dispatch(notifications.showFailure(error));
          console.error(textStatus);
        }),
    });
  };
}

export function getAccounts() {
  return (dispatch) => {
    return dispatch({
      payload: apiGet(Auth.getApiUrl() + '/landlords/bank_accounts')
        .done((response) => {
          dispatch(showAccounts(response.bank_accounts));
        })
        .catch((error, textStatus) => {
          dispatch(notifications.showFailure(error));
          console.error(textStatus);
        }),
    });
  };
}

export function getPortfolios() {
  return (dispatch) => {
    return dispatch({
      type: 'getPortfolios',
      payload: apiGet(Auth.getApiUrl() + '/landlords/portfolios')
        .done((response) => {
          dispatch(showPortfolios(response.portfolios));
        })
        .catch((error, textStatus) => {
          console.error(textStatus);
        }),
    });
  };
}

export function getProperties(options) {
  // Options:
  // for_multiselect: true will return list of properties -- {value: property.id, label: property.name}
  // for_multiselect_single_unit: true will return list of properties with single unit properties labeled and returning said unit id
  //                              {value: property.id, label: property.name, is_single_unit: true/false, unit_id: unit.id}
  let route = '/landlords/properties';
  if (options) {
    route += formatter.objectToQuery(options);
  }
  return (dispatch) => {
    return dispatch({
      type: 'LANDLORD_PROPERTIES::GET_PROPERTIES',
      payload: apiGet(Auth.getApiUrl() + route)
        .done((response) => {
          dispatch(showProperties(response.properties));
        })
        .catch((error, textStatus) => {
          dispatch(notifications.showFailure(error));
          console.error(textStatus);
        }),
    });
  };
}

export function getUnits(propertyId, options) {
  let route = `/landlords/properties/${propertyId}/units`;
  if (options) {
    route += formatter.objectToQuery(options);
  }
  return (dispatch) => {
    return dispatch({
      type: 'LANDLORD_PROPERTIES::FETCH_UNITS',
      payload: apiGet(Auth.getApiUrl() + route)
        .done((response) => {
          return dispatch(showUnits(response.data));
        })
        .catch((error, textStatus) => {
          dispatch(notifications.showFailure(error));
          console.error(textStatus);
        }),
    });
  };
}

export const clearUnits = () => showUnits([]);

export function getUnitTypes(propertyId, options) {
  let route = `/landlords/properties/${propertyId}/available_unit_types`;
  if (options) {
    route += formatter.objectToQuery(options);
  }
  return (dispatch) => {
    return dispatch({
      payload: apiGet(Auth.getApiUrl() + route)
        .done((response) => {
          return dispatch(setUnitTypes(response.unit_types));
        })
        .catch((error, textStatus) => {
          dispatch(notifications.showFailure(error));
          console.error(textStatus);
        }),
    });
  };
}

export function getPropertyRooms(propertyId, options) {
  let route = `/landlords/properties/${propertyId}/rooms`;
  if (options) {
    route += formatter.objectToQuery(options);
  }
  return (dispatch) => {
    return dispatch({
      payload: apiGet(Auth.getApiUrl() + route)
        .done((response) => {
          dispatch(storeRooms(response.data));
          return response;
        })
        .catch((error, textStatus) => {
          dispatch(notifications.showFailure(error));
          console.error(textStatus);
        }),
    });
  };
}

export function getUnitRooms(unitId, options) {
  let route = `/landlords/units/${unitId}/rooms`;
  if (options) {
    route += formatter.objectToQuery(options);
  }
  return (dispatch) => {
    return dispatch({
      payload: apiGet(Auth.getApiUrl() + route)
        .done((response) => {
          dispatch(storeRooms(response.data));
          return response;
        })
        .catch((error, textStatus) => {
          dispatch(notifications.showFailure(error));
          console.error(textStatus);
        }),
    });
  };
}

export function getAmenities(options) {
  // Options: for_multiselect: true will return list of amenities
  // used for multiselect.
  // property_id: will filter the amenities by property id
  let route = '/landlords/amenities';
  if (options) {
    route += formatter.objectToQuery(options);
  }
  return (dispatch) => {
    return dispatch({
      payload: apiGet(Auth.getApiUrl() + route)
        .done((response) => {
          dispatch(showAmenities(response.amenities));
          return response;
        })
        .catch((error, textStatus) => {
          dispatch(notifications.showFailure(error));
          console.error(textStatus);
        }),
    });
  };
}

export function tenants(options) {
  // Options: for_multiselect: true will return list of amenities
  // used for multiselect.
  // property_id: will filter the amenities by property id
  let route = '/landlords/tenants';
  if (options) {
    route += formatter.objectToQuery(options);
  }
  return (dispatch) => {
    return dispatch({
      payload: apiGet(Auth.getApiUrl() + route)
        .done((response) => {
          dispatch(showTenants(response.tenants));
        })
        .catch((error, textStatus) => {
          dispatch(notifications.showFailure(error));
          console.error(textStatus);
        }),
    });
  };
}

export function showTenants(tenants) {
  return {
    type: 'LANDLORD_TENANTS::SHOW_TENANTS',
    tenants,
  };
}

export function selectedTenantID(selectedTenantID) {
  return {
    type: 'LANDLORD_TENANTS::SELECT_TENANT',
    selectedTenantID,
  };
}

export function getAmenity(amenityID) {
  return (dispatch) => {
    return dispatch({
      payload: apiGet(Auth.getApiUrl() + `/landlords/amenities/${amenityID}`)
        .done((response) => {
          dispatch(showAmenity(response.amenity_details));
        })
        .catch((error, textStatus) => {
          dispatch(notifications.showFailure(error));
          console.error(textStatus);
        }),
    });
  };
}

export function showAmenity(amenityDetails) {
  return {
    type: 'LANDLORD_AMENITIES::SHOW_AMENITY',
    amenityDetails,
  };
}

function formatProperty(response) {
  // TODO: This should be cleaned up when redoing applications... we should not be saving true / false as strings!
  for (let key in response) {
    if (key === 'virtual_tour' || key === 'hide_unit_types') continue;

    if (response[key] === true) response[key] = 'true';
    else if (response[key] === false) response[key] = 'false';
    else if (key === 'business_hours_start') response[key] = formatter.toDate(response[key]);
    else if (key === 'business_hours_end') response[key] = formatter.toDate(response[key]);
  }
  return response;
}

function setUnitTypes(unitTypes) {
  return {
    type: 'LANDLORD_TENANTS::STORE_UNIT_TYPES',
    unitTypes,
  };
}

export function getPropertyPublic(propertyId) {
  return (dispatch) => {
    return dispatch({
      payload: apiGet(Auth.getApiUrl() + `/landlords/public_properties/${propertyId}`)
        .done((response) => {
          dispatch(showProperty(formatProperty(response)));
          dispatch(setAccentColor(response.accent_color));
          dispatch(setLandlordLogo(response.landlord_logo_url));
          dispatch(setUnitTypes(response.unit_types));
          dispatch(setGoogleTagId(response.google_tag_id));
        })
        .catch((error, textStatus) => {
          console.error(textStatus);
          return dispatch(notifications.showFailure(error));
        }),
    });
  };
}

export function getPropertyPublicForShowings(propertyId) {
  return (dispatch) => {
    return dispatch({
      payload: apiGet(Auth.getApiUrl() + `/landlords/public_properties/${propertyId}`)
        .done((response) => {
          dispatch(showProperty(formatProperty(response)));
          dispatch(setAccentColor(response.accent_color));
          dispatch(setLandlordLogo(response.landlord_logo_url));
          dispatch(setUnitTypes(response.unit_types));
          dispatch(setGoogleTagId(response.google_tag_id));
        })
        .catch((error, textStatus) => {
          console.error(textStatus);
          return dispatch(notifications.showFailure(error));
        }),
    });
  };
}

export function getProperty(propertyId) {
  return (dispatch) => {
    return dispatch({
      payload: apiGet(Auth.getApiUrl() + `/landlords/properties/${propertyId}`)
        .done((response) => {
          dispatch(showProperty(formatProperty(response)));
        })
        .catch((error, textStatus) => {
          dispatch(notifications.showFailure(error));
          console.error(textStatus);
        }),
    });
  };
}

export function inactivateProperty(property_id) {
  return (dispatch) => {
    return dispatch({
      payload: apiDelete(Auth.getApiUrl() + `/landlords/properties/${property_id}`)
        .done((response) => {
          dispatch(notifications.showSuccess('propertySuccessfullyInactivated'));
          dispatch(getProperties());
        })
        .catch((error, textStatus) => {
          dispatch(notifications.showFailure(error));
          console.error(textStatus);
        }),
    });
  };
}

export function getAgents(options = {}) {
  const agentsPath = `/landlords/agents`;
  const url = new URL(Auth.getApiUrl() + agentsPath);
  let { manual_selection_agents_only, dayOfWeek, ...searchParams } = options;

  if (manual_selection_agents_only) {
    searchParams = {
      ...searchParams,
      manual_selection_agents_only: true,
      show_agent_availability: true,
      day_of_week: dayOfWeek,
    };
  }

  Object.entries(searchParams).forEach(([key, value]) => url.searchParams.append(key, value));

  return (dispatch) => {
    return dispatch({
      payload: apiGet(url.toString())
        .done((response) => {
          dispatch(receiveAgents(response));
        })
        .catch((error, textStatus) => {
          dispatch(notifications.showFailure(error));
          console.error(textStatus);
        }),
    });
  };
}

export function getOwners() {
  return (dispatch) => {
    return dispatch({
      payload: apiGet(Auth.getApiUrl() + '/landlords/owners?for_multiselect=true')
        .done((response) => {
          dispatch(receiveOwners(response.owners));
        })
        .catch((error, textStatus) => {
          dispatch(notifications.showFailure(error));
          console.error(textStatus);
        }),
    });
  };
}

export function getCurrentLeasesOfUnit(unitId, currentLeaseId, options = {}) {
  let url = `/landlords/properties/units/${unitId}/nonexpired_leases?current_lease_id=${currentLeaseId}`;
  if (!currentLeaseId && options.activeLeasesOnly) {
    url = `/landlords/properties/units/${unitId}/all_completed_unit_leases`;
  }

  return (dispatch) => {
    return dispatch({
      type: 'LANDLORD_PROPERTIES::FETCH_NONEXPIRED_LEASES',
      payload: apiGet(Auth.getApiUrl() + url)
        .done((response) => {
          dispatch(showCurrentLeasesOfUnit(response));
        })
        .catch((error, textStatus) => {
          dispatch(notifications.showFailure(error));
          console.error(textStatus);
        }),
    });
  };
}
export function getUnit(id, options = {}) {
  return (dispatch, getState) => {
    if (!id) return dispatch(saveUnit(id));

    let url = `/landlords/properties/units/${id}`;
    if (options.is_renewal) {
      url = `/landlords/properties/units/${id}?is_renewal=true`;
    }

    return dispatch({
      type: 'LANDLORD_PROPERTIES::FETCH_UNIT',
      payload: apiGet(Auth.getApiUrl() + url)
        .done((response) => {
          dispatch(saveUnit(response));
        })
        .catch((error) => {
          dispatch(notifications.showFailure(error));
          console.error('request failed', error);
        }),
    });
  };
}

function saveUnit(unit) {
  return {
    type: 'LANDLORD_PROPERTIES::SAVE_UNIT',
    unit,
  };
}

export function getActiveLeaseOfUnit(unitId, options = { with_additional_occupants: false }) {
  // Options: with_additional_occupants: true will return a list of cotenants plus additional occupants

  let route = `/landlords/properties/units/${unitId}/active_lease`;
  if (options) {
    route += objectToQuery(options);
  }

  return (dispatch) => {
    return dispatch({
      payload: apiGet(Auth.getApiUrl() + route)
        .done((response) => {
          dispatch(showActiveLeaseOfUnit(response));
          return true;
        })
        .catch((error, textStatus) => {
          if (error.status === 404) {
            // Active Lease non existent
            dispatch(clearActiveLeaseOfUnit());
          } else {
            dispatch(notifications.showFailure(error));
            console.error(textStatus);
          }
          return false;
        }),
    });
  };
}

export function showCurrentLeasesOfUnit(unitDetails) {
  let leases = [];
  if (unitDetails.leases) {
    leases = unitDetails.leases.map((lease) => {
      return {
        ...lease,
        lease_start_date: formatter.toDate(lease.lease_start_date),
        lease_end_date: formatter.toDate(lease.lease_end_date),
      };
    });
  }

  return {
    type: 'LANDLORD_PROPERTIES::SHOW_CURRENT_LEASES_OF_UNIT',
    currentLeasesOfUnit: leases,
    currentConcessionsOfUnit: unitDetails.concessions || [],
  };
}

export function showActiveLeaseOfUnit(activeLease) {
  let unitActiveLease;
  if (activeLease) {
    unitActiveLease = {
      ...activeLease,
      lease_start_date: formatter.toDate(activeLease.lease_start_date),
      lease_end_date: formatter.toDate(activeLease.lease_end_date),
    };
  }
  return {
    type: 'LANDLORD_PROPERTIES::SHOW_UNIT_ACTIVE_LEASE',
    unitActiveLease,
  };
}

export const clearCurrentLeasesOfUnit = () => showCurrentLeasesOfUnit([]);

export const clearActiveLeaseOfUnit = () => showActiveLeaseOfUnit(null);

export function deleteAmenity(amenityId) {
  return (dispatch) => {
    return dispatch({
      payload: apiDelete(Auth.getApiUrl() + `/landlords/amenities/${amenityId}`)
        .done((response) => {
          dispatch(notifications.showSuccess('amenitySuccessfullyDeleted'));
        })
        .catch((error, textStatus) => {
          dispatch(notifications.showFailure('amenityDeleteFailed'));
          console.error(textStatus);
        }),
    });
  };
}

export function buildAmenityPayload(data) {
  let data_clone = JSON.parse(JSON.stringify(data));
  // upload amenity photo
  const multiPartFormData = new FormData();

  if (data.image?.[0]?.lastModified) {
    multiPartFormData.append('file', data.image[0]);
    multiPartFormData.append('document_type', data.image[0].document_type);
  }

  if (data['offline_starts_at']) {
    data_clone['offline_starts_at'] = formatter.toISO8601DateString(data['offline_starts_at']);
  }

  if (data['offline_ends_at']) {
    data_clone['offline_ends_at'] = formatter.toISO8601DateString(data['offline_ends_at']);
  }

  if (!data['offline']) {
    data_clone['offline'] = false;
  }

  if (data['property_for_multiselect']) {
    data_clone['property_id'] = data['property_for_multiselect'].map((obj) => obj['value']);
  }
  const formData = JSON.stringify(data_clone);
  multiPartFormData.append('data', formData);

  return multiPartFormData;
}

export function createAmenity(amenityId, data) {
  return (dispatch) => {
    return dispatch({
      payload: payload(amenityId, buildAmenityPayload(data))
        .done((response) => {
          dispatch(notifications.showSuccess('amenitySuccessfullyCreated'));
        })
        .catch((error) => {
          console.error('request failed', error);
          dispatch(notifications.showFailure('amenityCreateFailed'));
        }),
    });
  };
}

function payload(amenityId, multiPartFormData) {
  if (amenityId) {
    return apiPutMultipart(
      Auth.getApiUrl() + `/landlords/amenities/${amenityId}`,
      multiPartFormData
    );
  } else {
    return apiPostMultipart(Auth.getApiUrl() + '/landlords/amenities', multiPartFormData);
  }
}

export function showAmenities(amenities) {
  amenities = amenities.map((amenity) => {
    return {
      ...amenity,
      offline_starts_at: formatter.toDate(amenity.offline_starts_at),
      offline_ends_at: formatter.toDate(amenity.offline_ends_at),
    };
  });
  return {
    type: 'LANDLORD_PROPERTIES::SHOW_AMENITIES',
    amenities,
  };
}

export function updateAmenityAvailability(amenityId, formData) {
  return (dispatch) => {
    return dispatch({
      payload: apiPut(Auth.getApiUrl() + `/landlords/amenities/availability/${amenityId}`, formData)
        .done((response) => {
          dispatch(notifications.showSuccess('amenityAvailabilitySuccessfullyUpdated'));
        })
        .catch((error, textStatus) => {
          dispatch(notifications.showFailure('amenityAvailabilityUpdateFailed'));
          console.error(textStatus);
        }),
    });
  };
}

export function amenityAvailability(amenityId) {
  return (dispatch) => {
    return dispatch({
      payload: apiGet(Auth.getApiUrl() + `/landlords/amenities/availability/${amenityId}`)
        .done((response) => {
          dispatch(showAvailability(response.availability));
        })
        .catch((error, textStatus) => {
          dispatch(notifications.showFailure('amenityAvailabilityReadFailed'));
          console.error(textStatus);
        }),
    });
  };
}

export function showAvailability(availability) {
  return {
    type: 'LANDLORD_AMENITIES::SHOW_AVAILABILITY',
    availability,
  };
}

export function getPromotions() {
  return (dispatch) => {
    return dispatch({
      payload: apiGet(Auth.getApiUrl() + '/landlords/promotions')
        .done((response) => {
          dispatch(showPromotions(response.promotions));
        })
        .catch((error, textStatus) => {
          dispatch(notifications.showFailure(error));
          console.error(textStatus);
        }),
    });
  };
}

export function showPromotions(promotions_response) {
  const promotions = promotions_response.map((promotion) => {
    let expiration_date;
    if (promotion.expiration_date) {
      expiration_date = formatter.toDate(promotion.expiration_date);
    }
    return {
      ...promotion,
      expiration_date,
    };
  });
  return {
    type: 'LANDLORD_PROPERTIES::SHOW_PROMOTIONS',
    promotions,
  };
}

export function createOrUpdatePromotion(promotionId, data) {
  return (dispatch) => {
    // promotion photo upload
    const multiPartFormData = new FormData();

    if (data.image[0].lastModified) {
      multiPartFormData.append('image', data.image[0]);
    }

    data['property_ids'] = data['properties'].map((property) => {
      return property.value;
    });
    if (data['expiration_date']) {
      data['expiration_date'] = formatter.toISO8601DateString(data['expiration_date']);
    }
    const formData = JSON.stringify(data);
    multiPartFormData.append('data', formData);

    return dispatch({
      payload: promotionPayload(promotionId, multiPartFormData)
        .done((response) => {
          dispatch(notifications.showSuccess('promotionSuccessfullyCreated'));
          dispatch(getPromotions());
        })
        .catch((error) => {
          dispatch(notifications.showFailure(error));
          console.error('request failed', error);
        }),
    });
  };
}

function promotionPayload(promotionId, multiPartFormData) {
  if (promotionId) {
    return apiPutMultipart(
      Auth.getApiUrl() + `/landlords/promotions/${promotionId}`,
      multiPartFormData
    );
  } else {
    return apiPostMultipart(Auth.getApiUrl() + '/landlords/promotions', multiPartFormData);
  }
}

export function deletePromotion(promotionId) {
  return (dispatch) => {
    return dispatch({
      payload: apiDelete(Auth.getApiUrl() + `/landlords/promotions/${promotionId}`)
        .done((response) => {
          dispatch(notifications.showSuccess('promotionSuccessfullyDeleted'));
        })
        .catch((error, textStatus) => {
          dispatch(notifications.showFailure(error));
          console.error(textStatus);
        }),
    });
  };
}

export function getVisitors(options) {
  // Options: show_all: true will return list of all visitors.
  let route = '/landlords/visitors';
  if (options) {
    route += objectToQuery(options);
  }
  return (dispatch) => {
    return dispatch({
      payload: apiGet(Auth.getApiUrl() + route)
        .done((response) => {
          dispatch({
            type: 'LANDLORD_PROPERTIES::DISPLAY_VISITORS',
            visitors: response.visitors,
          });
        })
        .catch((error, textStatus) => {
          dispatch(notifications.showFailure(error));
          console.error(textStatus);
        }),
    });
  };
}

export function createOrUpdateVisitor(formData, visitorId) {
  let successVerbiage = 'Visitor Successfully Added';
  if (visitorId) {
    successVerbiage = 'Visitor Successfully Updated';
  }
  formData['expected_arrival_at'] = formatter.toISO8601DateTimeString(
    formData['expected_arrival_at']
  );
  formData['expected_departure_at'] = formatter.toISO8601DateTimeString(
    formData['expected_departure_at']
  );

  return (dispatch) => {
    return dispatch({
      payload: visitorPayload(visitorId, formData)
        .done((response) => {
          dispatch(notifications.showSuccess(successVerbiage));
          dispatch(getVisitors());
        })
        .catch((error) => {
          dispatch(notifications.showFailure(error));
          console.error('request failed', error);
        }),
    });
  };
}

function visitorPayload(visitorId, data) {
  if (visitorId) {
    return apiPut(Auth.getApiUrl() + `/landlords/visitors/${visitorId}`, data);
  } else {
    data.property_id = data.property.value;
    if (data.tenant) {
      data.lead_id = data.tenant.value;
    }
    if (data.unit_selected) {
      data.unit_id = data.unit_selected.value;
    }
    return apiPost(Auth.getApiUrl() + '/landlords/visitors', data);
  }
}

export function getSurveyQuestions(surveyId) {
  return (dispatch) => {
    return dispatch({
      payload: apiGet(Auth.getApiUrl() + `/landlords/survey_questions/${surveyId}`)
        .done((response) => {
          dispatch(showSurveyQuestions(response.survey_questions));
        })
        .catch((error, textStatus) => {
          dispatch(notifications.showFailure(error));
          console.error(textStatus);
        }),
    });
  };
}

export function getSurveyResults(surveyId) {
  return (dispatch) => {
    return dispatch({
      payload: apiGet(Auth.getApiUrl() + `/landlords/survey_results/${surveyId}`)
        .done((response) => {
          dispatch(showSurveyResults(response.survey_results));
        })
        .catch((error, textStatus) => {
          dispatch(notifications.showFailure(error));
          console.error(textStatus);
        }),
    });
  };
}

export function showSurveyResults(surveyResults) {
  return {
    type: 'LANDLORD_PROPERTIES::SHOW_SURVEY_RESULTS',
    surveyResults,
  };
}

export function showSurveyQuestions(surveyQuestions) {
  return {
    type: 'LANDLORD_PROPERTIES::SHOW_SURVEY_QUESTIONS',
    surveyQuestions,
  };
}

export function getSurveys() {
  return (dispatch) => {
    return dispatch({
      payload: apiGet(Auth.getApiUrl() + '/landlords/surveys')
        .done((response) => {
          dispatch(showSurveys(response.surveys));
        })
        .catch((error, textStatus) => {
          dispatch(notifications.showFailure(error));
          console.error(textStatus);
        }),
    });
  };
}

export function showSurveys(surveys_response) {
  const surveys = surveys_response.map((survey) => {
    let expiration_date;
    if (survey.expiration_date) {
      expiration_date = formatter.toDate(survey.expiration_date);
    }
    return {
      ...survey,
      expiration_date,
    };
  });
  return {
    type: 'LANDLORD_PROPERTIES::SHOW_SURVEYS',
    surveys,
  };
}

function surveyPayload(surveyId, formData) {
  if (surveyId) {
    return apiPut(Auth.getApiUrl() + `/landlords/surveys/${surveyId}`, formData);
  } else {
    return apiPost(Auth.getApiUrl() + '/landlords/surveys', formData);
  }
}

export function createOrUpdateSurvey(formData, surveyId, publish) {
  if (formData['properties']) {
    formData['property_ids'] = formData['properties'].map((property) => property.value);
  }
  if (formData['is_poll']) {
    const SURVEY_TYPE_POLL = 4;
    formData['survey_questions'][0]['question_type'] = SURVEY_TYPE_POLL;
  }
  formData['publish'] = publish;

  const successVerbiage = surveyId ? 'surveySuccessfullyUpdated' : 'surveySuccessfullyCreated';

  return (dispatch) => {
    return dispatch({
      payload: surveyPayload(surveyId, formData)
        .done((response) => {
          dispatch(notifications.showSuccess(successVerbiage));
        })
        .catch((error) => {
          dispatch(notifications.showFailure(error));
          console.error('request failed', error);
        }),
    });
  };
}

export function deleteSurvey(surveyId) {
  return (dispatch) => {
    return dispatch({
      payload: apiDelete(Auth.getApiUrl() + `/landlords/surveys/${surveyId}`)
        .done((response) => {
          dispatch(notifications.showSuccess('surveySuccessfullyDeleted'));
        })
        .catch((error) => {
          dispatch(notifications.showFailure(error));
          console.error('request failed', error);
        }),
    });
  };
}

export function createPropertyDocument(data, propertyId) {
  return (dispatch) => {
    const multiPartFormData = new FormData();
    multiPartFormData.append('file', data.new_document.file[0]);
    const document_sub_type =
      data.new_document.document_sub_type || data.new_document.document_sub_type_name;
    if (document_sub_type) {
      multiPartFormData.append('document_sub_type', document_sub_type);
    }

    return dispatch({
      payload: apiPostMultipart(
        Auth.getApiUrl() + `/landlords/properties/${propertyId}/documents`,
        multiPartFormData
      )
        .done((response) => {
          dispatch(notificationActions.showSuccess('successfullyUploadedDocument'));
        })
        .catch((error) => {
          console.error('request failed', error);
          dispatch(notificationActions.showFailure(error));
        }),
    });
  };
}

export function deletePropertyDocument(propertyId, documentId) {
  return (dispatch) => {
    return dispatch({
      payload: apiDelete(
        Auth.getApiUrl() + `/landlords/properties/${propertyId}/documents/${documentId}`
      )
        .done((response) => {
          dispatch(notificationActions.showSuccess('successfullyDeletedDocument'));
        })
        .catch((error) => {
          console.error('request failed', error);
          dispatch(notificationActions.showFailure(error));
        }),
    });
  };
}

export function createAmenityCalendarHold(formData) {
  return (dispatch) => {
    return dispatch({
      payload: apiPost(`${Auth.getApiUrl()}/calendar_holds`, formData)
        .done((response) => {
          dispatch(notifications.showSuccess('successfullyCreatedCalendarHold'));
        })
        .catch((error, textStatus) => {
          dispatch(notificationActions.showFailure(error));
          console.error(textStatus);
        }),
    });
  };
}

export function getCalendarHolds(amenityId) {
  return (dispatch) => {
    return dispatch({
      payload: apiGet(`${Auth.getApiUrl()}/landlords/amenities/${amenityId}/calendar_holds`)
        .done((response) => {
          dispatch(showCalendarHolds(response.data));
        })
        .catch((error, textStatus) => {
          dispatch(notificationActions.showFailure(error));
          console.error(textStatus);
        }),
    });
  };
}

function showCalendarHolds(res) {
  let calendarHolds = res.map((calendarHold) => ({
    ...calendarHold.attributes,
    start_date: calendarHold.attributes.start_at,
    start_time: toFriendlyTimeString(calendarHold.attributes.start_at),
    end_date: calendarHold.attributes.end_at,
    end_time: toFriendlyTimeString(calendarHold.attributes.end_at),
  }));
  delete calendarHolds.start_at;
  delete calendarHolds.end_at;
  return {
    type: 'LANDLORD_AMENITIES::SHOW_CALENDAR_HOLDS',
    calendarHolds,
  };
}

export function cancelCalendarHold(calendarHoldId) {
  return (dispatch) => {
    return dispatch({
      payload: apiPut(`${Auth.getApiUrl()}/calendar_holds/${calendarHoldId}`)
        .done((response) => {
          dispatch(notifications.showSuccess('successfullyDeletedCalendarHold'));
        })
        .catch((error, textStatus) => {
          dispatch(notificationActions.showFailure(error));
          console.error(textStatus);
        }),
    });
  };
}

// Gets all residents (past and present) with PAP details used in Batch Payments Form
export function getResidentsOfUnitWithPAPDetails(unitId) {
  let url = `/landlords/properties/units/${unitId}/residents`;

  return (dispatch) => {
    return dispatch({
      type: 'LANDLORD_PROPERTIES::FETCH_UNIT_RESIDENTS',
      payload: apiGet(Auth.getApiUrl() + url)
        .done((response) => {
          dispatch(showResidentsWithPAPDetails(response.data));
        })
        .catch((error, textStatus) => {
          dispatch(notifications.showFailure(error));
          console.error(textStatus);
        }),
    });
  };
}

export function showResidentsWithPAPDetails(residents) {
  return {
    type: 'LANDLORD_PROPERTIES::SHOW_UNIT_RESIDENTS',
    residents,
  };
}

export const clearResidentsWithPAPDetails = () => showResidentsWithPAPDetails([]);

export function createMaintenanceWaiver(formData, propertyId) {
  return (dispatch) => {
    return dispatch({
      type: 'LANDLORD_PROPERTIES::CREATE_MAINTENANCE_WAIVER',
      payload: apiPost(
        Auth.getApiUrl() + `/landlords/properties/${propertyId}/maintenance_waiver`,
        formData
      ).catch((error, textStatus) => {
        throw new Error();
      }),
    });
  };
}

export function createGoogleReview(formData, propertyId) {
  return (dispatch) => {
    return dispatch({
      type: 'LANDLORD_PROPERTIES::CREATE_GOOGLE_REVIEW',
      payload: apiPut(
        Auth.getApiUrl() + `/landlords/properties/${propertyId}/google_review`,
        formData
      ).catch((error, textStatus) => {
        throw new Error();
      }),
    });
  };
}

export function toggleDigitalLegalNotices(formData, propertyId) {
  return (dispatch) => {
    return dispatch({
      type: 'LANDLORD_PROPERTIES::TOGGLE_LEGAL_NOTICES',
      payload: apiPut(
        Auth.getApiUrl() + `/landlords/properties/${propertyId}/toggle_legal_notices`,
        {}
      ).catch((error, textStatus) => {
        throw new Error();
      }),
    });
  };
}

export function createProspectMessage(formData, propertyId) {
  return (dispatch) => {
    return dispatch({
      type: 'LANDLORD_PROPERTIES::CREATE_PROSPECT_MESSAGE',
      payload: apiPost(
        Auth.getApiUrl() + `/landlords/properties/${propertyId}/application_message`,
        formData
      ).catch((error, textStatus) => {
        throw new Error();
      }),
    });
  };
}

export function toggleRequestAssistance(propertyId) {
  return (dispatch) => {
    return dispatch({
      type: 'LANDLORD_PROPERTIES::TOGGLE_REQUEST_ASSISTANCE',
      payload: apiPut(
        Auth.getApiUrl() + `/landlords/properties/${propertyId}/toggle_request_assistance`,
        {}
      ).catch((error) => {
        return dispatch(notificationActions.showFailure(error));
      }),
    });
  };
}

export const toggleMaintenanceDispatchOnProperty = async (propertyId, toggle) => {
  return async (dispatch) => {
    const url = `${Auth.getApiUrl()}/landlords/properties/${propertyId}/toggle_maintenance_dispatch`;
    try {
      await apiPut(url);
      dispatch(
        notificationActions.showSuccess(`toggleMaintenanceDispatch.${toggle ? 'on' : 'off'}`)
      );
      return dispatch(getProperties());
    } catch (e) {
      return dispatch(notificationActions.showFailure(e));
    }
  };
};

export const CHECK_UNIT_AVAILABILITY_FOR_LEASE =
  'LANDLORD_PROPERTIES::CHECK_UNIT_AVAILABILITY_FOR_LEASE';

export function checkUnitAvailabilityForLease({ unitId, leaseStartDate, leaseEndDate }) {
  const data = {
    lease_start_date: leaseStartDate,
    lease_end_date: leaseEndDate,
  };
  return (dispatch) => {
    return dispatch({
      type: CHECK_UNIT_AVAILABILITY_FOR_LEASE,
      payload: apiGet(
        Auth.getApiUrl() + `/landlords/properties/unit/${unitId}/availability_for_lease`,
        data
      ).then((response) => {
        dispatch({
          type: `${CHECK_UNIT_AVAILABILITY_FOR_LEASE}_FULFILLED`,
        });
        return response;
      }),
    });
  };
}
