import store from 'store';
import enTranslations from 'assets/localization/translations/en.locale.json';
import frTranslations from 'assets/localization/translations/fr.locale.json';
import nlTranslations from 'assets/localization/translations/nl.locale.json';
import { deepDiver } from 'helpers/utilities';
import startCase from 'lodash/startCase';

import en from 'world_countries_lists/data/countries/en/world.json';
import nl from 'world_countries_lists/data/countries/nl/world.json';
import fr from 'world_countries_lists/data/countries/fr/world.json';
import states from 'world_countries_lists/data/subdivisions/subdivisions.json';
import * as dateFnsLocales from 'date-fns/locale';

import frFR from 'antd/lib/locale/fr_FR';
import enUS from 'antd/lib/locale/en_US';
import nlNL from 'antd/lib/locale/nl_NL';

const translations = {
  en: enTranslations,
  fr: frTranslations,
  nl: nlTranslations,
};

const DEFAULT_KEY = 'en';

export const getCurrentLocale = () => {
  if (!window || !window.localStorage)
    throw Error('getCurrentLocale must be called in the browser');

  if (window.localStorage.locale) return window.localStorage.locale;

  // Default languages if locale is not stored.
  if (
    store.getState().userTypes.defaultLanguages &&
    store.getState().userTypes.defaultLanguages.length > 0
  ) {
    return store.getState().userTypes.defaultLanguages[0];
  }

  return DEFAULT_KEY;
};

export const getCurrentAntdLocaleTranslations = () => {
  const locale = getCurrentLocale();

  switch (locale) {
    case 'en':
      return enUS;
    case 'fr':
      return frFR;
    case 'nl':
      return nlNL;
    default:
      return enUS;
  }
};

const mapToDateFnsLocale = {
  en: dateFnsLocales.enUS,
  fr: dateFnsLocales.fr,
  nl: dateFnsLocales.nl,
};

export const getCurrentDateFnsLocale = () => mapToDateFnsLocale[getCurrentLocale()];

// otherwise : a text used if the key is not found in global.local.json.
export const getTranslatedString = (key, params = null, otherwise = null, forceLocale = null) => {
  if (!window || !window.localStorage)
    throw Error('getTranslatedString must be called in the browser');

  const locale = forceLocale || getCurrentLocale();
  let string = translations[locale][key];

  if (!string) {
    string = otherwise;
  }

  if (!string && locale !== DEFAULT_KEY && window.ENV === 'production') {
    window.Rollbar.error(`MISSING ${locale} translations for ${key}`);
    string = translations[DEFAULT_KEY][key];
  }

  if (!string) {
    window.Rollbar.error(`MISSING ALL translations for ${key}`);
    return `MISSING  ${key}`;
  }
  return interpolate(string, params);
};

/**
 * Get translated string for button
 * Button labels are always title case (Add an Event, Change Associations)
 * @param {String} key
 * @param {Array} params
 * @param {String} otherwise
 */
export const getTranslatedBtnLabel = (key, params, otherwise = null) =>
  startCase(getTranslatedString(key, params, otherwise).toLowerCase())
    .split(' ')
    .map((word) => {
      if (['A', 'An'].includes(word)) {
        return word.toLowerCase();
      }
      return word;
    })
    .join(' ');

// This function is useful for translating things that could be user input OR certain fixed values
// Example is name and description of batches
// They could be entered by user, or they could be generated by system in which case it needs to be translated
// If the parameter is formatted like: $T:key then the key will be translated
// otherwise the original value will be used
export const getTranslatedName = (name) => {
  if (typeof name === 'string' && name.startsWith('$T') && !!name.split('|')[1]) {
    const translateVariables = name.split('|')[1];
    const arrayOfVariablesForTranslate = translateVariables.split(', ');
    let translateVariablesObject = {};
    arrayOfVariablesForTranslate.map(
      (keyPair) => (translateVariablesObject[keyPair.split(':: ')[0]] = keyPair.split(':: ')[1])
    );

    return getTranslatedString(name.split(':')[1].split('|')[0], translateVariablesObject);
  } else if (typeof name === 'string' && name.startsWith('$T')) {
    return getTranslatedString(name.split(':')[1]);
  } else {
    return name;
  }
};

const formatCountries = (countries) =>
  countries.map((country) => ({
    code: country.alpha2.toUpperCase(),
    name: country.name,
  }));

export const getCountriesList = () => {
  const locale = getCurrentLocale();

  switch (locale) {
    case 'en':
      return formatCountries(en);
    case 'fr':
      return formatCountries(fr);
    case 'nl':
      return formatCountries(nl);
    default:
      return formatCountries(en);
  }
};

// for some reason world_countries_lists doesn't include state data
// in multiple languages, so it's only english for now
export const statesByCountry = states.reduce((acc, curr) => {
  const countryKey = curr.country.toLowerCase();
  return { ...acc, [countryKey]: [...(acc[countryKey] || []), curr] };
}, {});

export const getLandlordVerbiageOrTranslatedString = (
  verbiageKey,
  params,
  verbiagesPassed = null
) => {
  const locale = getCurrentLocale();
  const verbiages = verbiagesPassed || store.getState().userTypes?.verbiages;

  if (verbiages && deepDiver(verbiages, verbiageKey) && deepDiver(verbiages, verbiageKey)[locale]) {
    return interpolate(deepDiver(verbiages, verbiageKey)[locale], params);
  }

  return getTranslatedString(verbiageKey, params);
};

export const translateMessage = (message) => {
  const currentLanguage = getCurrentLocale();
  if (message in translations[currentLanguage]) {
    return translations[currentLanguage][message];
  } else {
    return message;
  }
};

function interpolate(string, params) {
  if (!params) return string;

  return string
    .split('${')
    .reduce((prev, curr, i) => {
      if (i === 0) return prev.concat([curr]);
      return prev.concat(curr.split('}'));
    }, [])
    .reduce((prev, curr, i) => {
      if (i % 2 === 0) {
        return prev + curr;
      } else {
        return prev + params[curr];
      }
    }, '');
}

const c = {};
Object.keys(translations.en).forEach((key) => {
  c[key] = {
    copy: { en: translations.en[key], fr: translations.fr[key], nl: translations.nl[key] },
  };
});

export const contentNodes = c;

const AuditedChangesTranslations = {
  is_generic: 'historyTab.changes.isGeneric',
  status: 'common.status',
  approved: 'landlordPortal.statusTag.apiStatus.approved',
  client_reference_id: 'historyTab.changes.clientReferenceId',
  draft_lease_data: 'historyTab.changes.draftLeaseData',
  cotenant_full_names: 'historyTab.changes.cotenantFullNames',
  signature_request_id: 'historyTab.changes.signatureRequestId',
  signature_request_lease_document_file_name:
    'historyTab.changes.signatureRequestLeaseDocumentFileName',
  signature_request_lease_document_file_size:
    'historyTab.changes.signatureRequestLeaseDocumentFileSize',
  signature_request_lease_document_updated_at:
    'historyTab.changes.signatureRequestLeaseDocumentUpdatedAt',
  signature_request_lease_document_content_type:
    'historyTab.changes.signatureRequestLeaseDocumentContentType',
  current_renewal_process_id: 'historyTab.changes.current_renewal_process_id',
  cancellation_reason:
    'landlordPortal.applicationManagement.leaseOverview.cancelLeaseDrawer.cancellationReason',
  cancellation_description: 'historyTab.changes.cancellation_description',
  expired: 'landlordPortal.incentives.expired',
  cancelled: 'landlordPortal.landlordMaintenance.cancelled',
  lease_id: 'landlordPortal.dashboard.leaseId',
  agent_id: 'historyTab.changes.agent_id',
  move_in: 'landlordPortal.leases.components.currentLease.leaseDates.moveInDate',
  lease_end_date: 'landlordPortal.leases.components.overview.leaseEndDate',
  payment_received: 'landlordPortal.statusTag.apiStatus.paymentReceived',
  has_guarantor: 'historyTab.changes.has_guarantor',
  applied: 'historyTab.changes.applied',
  welcome_package_sent: 'historyTab.changes.welcome_package_sent',
  lease_start_date: 'tenantPortal.lease.startDate',
  services_and_utilities: 'services_and_utilities',
  coapplicants_in_application: 'historyTab.changes.coapplicants_in_application',
};

export const getHumanizeChangeTranslated = (key) => {
  if (AuditedChangesTranslations[key]) return getTranslatedString(AuditedChangesTranslations[key]);

  console.warn('Missing key to be translated: ', key);

  return startCase(key);
};
