import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Field, reduxForm } from 'redux-form';

import { DatePicker } from 'redux-form-material-ui';

import * as validator from 'helpers/Validator';
import * as formatter from 'helpers/Formatter';
import { BooleanRadioButtons } from 'helpers/BooleanRadioButtons';
import { deepDiver } from 'helpers/utilities';

import TenantResidentVerbiageHelper from 'components/shared/TenantResidentVerbiageHelper';

import landlordTenantsReducer from 'reducers/landlordTenantsReducer';
import { hostIsWestbank, hostIsLifestyleRentals } from 'helpers/CurrentWindowLocation';
import { getTranslatedString } from 'helpers/i18n';

let LeaseTermPage = class extends React.Component {
  state = { leaseStartDate: null };

  render() {
    const {
      handleSubmit,
      name,
      currentLeasesOfUnit,
      isRenewal,
      leaseDetails,
      unitDetails,
      changeFieldValue,
    } = this.props;

    const disablePastDates = (date) => {
      let rightnow = new Date();
      rightnow.setHours(0, 0, 0, 0);
      date.setHours(0, 0, 0, 0);
      return date < rightnow;
    };

    const doubleUnitBookCheck = (value, fields) => {
      return validator.doubleUnitBook(value, fields, currentLeasesOfUnit, isRenewal);
    };

    const onEndChange = (e, value) => {
      const leaseStartDate =
        this.state.leaseStartDate || deepDiver(leaseDetails, 'lease_start_date');
      const leaseEndDate = value || deepDiver(leaseDetails, 'lease_end_date');
      const lengthInMonths = formatter.differenceInMonthsRounded(leaseStartDate, leaseEndDate);

      let absolute_rent = unitDetails.absolute_rent;
      let absolute_rent_six_months = unitDetails.absolute_rent_six_months;
      let market_rent = unitDetails.market_rent;
      let depositAmount = unitDetails.deposit_amount;
      if (depositAmount === '0') {
        depositAmount = null;
      }

      if (name == 'renewal_creator_form' && leaseDetails.lease_items.length > 0) {
        leaseDetails.lease_items.forEach((item) => {
          item.start_date = leaseStartDate;
          item.end_date = leaseEndDate;
        });
      }

      if (name === 'renewal_creator_form' && unitDetails.absolute_rent_renewal !== 0) {
        absolute_rent = unitDetails.absolute_rent_renewal;
        absolute_rent_six_months = unitDetails.absolute_rent_six_months_renewal;
        market_rent = unitDetails.absolute_rent_renewal;
      }

      if (lengthInMonths > 11) {
        changeFieldValue('monthly_rent', absolute_rent);
        changeFieldValue('optimal_rent', market_rent);
        if (!leaseDetails.discounted_deposit_used) {
          changeFieldValue('deposit_amount', depositAmount || absolute_rent);
        }
        return;
      }

      if (lengthInMonths === null || lengthInMonths <= 5) {
        changeFieldValue('monthly_rent', market_rent);
        changeFieldValue('optimal_rent', market_rent);
        if (!leaseDetails.discounted_deposit_used) {
          changeFieldValue('deposit_amount', depositAmount || market_rent);
        }
        return;
      }

      if (lengthInMonths <= 11 && lengthInMonths > 5) {
        changeFieldValue('monthly_rent', absolute_rent_six_months);
        changeFieldValue('optimal_rent', market_rent);
        if (!leaseDetails.discounted_deposit_used) {
          changeFieldValue('deposit_amount', depositAmount || absolute_rent_six_months);
        }
      }
    };

    return (
      <div>
        <form onSubmit={handleSubmit}>
          <h3 style={{ textAlign: 'center' }}> Lease Terms </h3>
          <div style={{ textAlign: 'center', fontSize: '14px' }}>
            It&rsquo;s best practice to have all of your leases start and end on the first of the
            month. <br /> To accommodate move-ins prior to the first of the month, provide the
            desired move in date in the early move-in field.
          </div>
          <div className="row" style={{ marginTop: '15px' }}>
            <div className="col-sm-6">
              <Field
                name="lease_start_date"
                component={DatePicker}
                openToYearSelection={true}
                style={{ fontSize: '14px', width: '100%' }}
                formatDate={formatter.toFriendlyDateString}
                autoOk={true}
                textFieldStyle={{ fontSize: '14px', width: '100%' }}
                floatingLabelText="Start Lease Date"
                validate={[validator.required, validator.dateLeaseRange, doubleUnitBookCheck]}
                onChange={(e, value) => this.setState({ leaseStartDate: value }, onEndChange())}
              />
            </div>
            <div className="col-sm-6">
              <Field
                name="lease_end_date"
                component={DatePicker}
                openToYearSelection={true}
                style={{ fontSize: '14px', width: '100%' }}
                formatDate={formatter.toFriendlyDateString}
                autoOk={true}
                textFieldStyle={{ fontSize: '14px', width: '100%' }}
                floatingLabelText="End Lease Date"
                shouldDisableDate={disablePastDates}
                validate={[validator.required, validator.futureDate, validator.dateLeaseRange]}
                onChange={(e, value) => onEndChange(e, value)}
              />
            </div>
          </div>
          {name === 'lease_creator_form' && (
            <div className="row" style={{ marginTop: '15px' }}>
              <div style={{ textAlign: 'center', marginTop: '20px' }}>
                <h4> Early Move-in </h4>
                <div style={{ fontSize: '14px' }}>
                  Select the <TenantResidentVerbiageHelper />
                  &rsquo;s desired move-in date if it is different from the lease start date above.
                </div>
              </div>
              <div className="col-sm-12" style={{ textAlign: 'center', marginTop: '15px' }}>
                <Field
                  name="early_move_in_date"
                  component={DatePicker}
                  openToYearSelection
                  style={{ fontSize: '14px', width: '100%' }}
                  formatDate={formatter.toFriendlyDateString}
                  autoOk
                  textFieldStyle={{ fontSize: '14px', width: '100%' }}
                  floatingLabelText="Move In Date"
                  validate={[validator.moveInDate]}
                />
              </div>
              {_renderIsMonthToMonth()}
            </div>
          )}

          {name === 'lease_creator_form' && (
            <div className="row" style={{ marginTop: '15px' }}>
              <div style={{ textAlign: 'center', marginTop: '20px' }}>
                <div style={{ fontSize: '14px' }}>
                  {getTranslatedString('addProratedRentCharge')}
                </div>
              </div>
              <div className="col-sm-12" style={{ textAlign: 'center', margin: '15px 0' }}>
                <BooleanRadioButtons
                  name="create_prorated_charge"
                  style={{ width: '187px', margin: 'auto' }}
                />
                <Field name="create_prorated_charge" component={formatter.RenderError} />
              </div>
            </div>
          )}
          <div className="row" style={{ marginTop: '15px' }}>
            <div style={{ textAlign: 'center', marginTop: '20px' }}>
              <h4> Renter&#39;s Insurance </h4>
              <div style={{ fontSize: '14px' }}>
                Do you require renter&#39;s insurance on this unit?
              </div>
            </div>
          </div>
          <div className="row" style={{ textAlign: 'center', marginTop: '15px' }}>
            <BooleanRadioButtons
              name="renters_insurance_required"
              style={{ width: '120px', display: 'inline-flex' }}
            />
          </div>
          {_renderIsMonthToMonth(leaseDetails.is_manual_renewal)}
        </form>
      </div>
    );
  }
};

const _renderIsMonthToMonth = (isManualRenewal = false) => {
  if (!hostIsWestbank() && !hostIsLifestyleRentals() && !isManualRenewal) return;

  return (
    <Fragment>
      <div className="row">
        <div style={{ textAlign: 'center', marginTop: '20px' }}>
          <h4 style={{ marginTop: '15px' }}> Month to Month </h4>
          <div style={{ fontSize: '14px' }}>Is this lease month to month?</div>
        </div>
      </div>
      <div className="row" style={{ textAlign: 'center', marginTop: '15px' }}>
        <BooleanRadioButtons
          name="month_to_month"
          style={{ width: '120px', display: 'inline-flex' }}
        />
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state, ownProps) => {
  return {
    form: ownProps.name,
  };
};

LeaseTermPage = compose(
  connect(mapStateToProps),
  reduxForm({
    destroyOnUnmount: false, // <------ preserve form data
    forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
  })
)(LeaseTermPage);

LeaseTermPage = connect(
  (state) => ({
    initialValues: state.landlordTenants.leaseDetails, // pull initial values from account reducer
  }),
  { load: landlordTenantsReducer } // bind account loading action creator
)(LeaseTermPage);

export default LeaseTermPage;
