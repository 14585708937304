//LIBRARIES
import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import FlatButton from 'material-ui/FlatButton';
import { submit, isDirty, isSubmitting } from 'redux-form';

//HELPERS
import { colors } from 'helpers/ColorHelper';
import { AccentColor } from 'helpers/AccentColorHelper';
import { getTranslatedString } from 'helpers/i18n';

const StyledChangesLabel = styled.span`
  width: 100%;
  text-align: right;
  padding-right: 1rem;
`;
class FooterButtons extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      disableBtn: false,
    };
  }

  disableSubmit = () => {
    if (this.props.submitting) {
      return true;
    }
    return this.props.disableSubmitOverride && !this.state.disableBtn ? false : !this.props.dirty;
  };

  onSubmit = () => {
    const { submitAction, submit, formName } = this.props;

    this.setState({ disableBtn: true });

    if (submitAction) {
      submitAction();
    } else {
      submit(formName);
    }

    //Required to ensure user doesn't spam the next button and send multiple call to the backend
    //pausing 0.5s on each page
    setTimeout(() => {
      this.setState({ disableBtn: false });
    }, 500);
  };

  render() {
    const {
      submitName,
      backAction,
      backName,
      deleteAction,
      deleteName,
      hideSubmitButton,
      dirty,
      showUnsavedChangesIndicator,
      position,
      style={},
    } = this.props;
    const isSubmitDisabled = this.disableSubmit();

    return (
      <div className="form_sticky_footer_buttons" style={{ position, ...style }}>
        <FlatButton
          label={backName || getTranslatedString('back')}
          type="button"
          className="previous"
          onClick={backAction}
          backgroundColor="#fff"
          hoverColor="rgba(0,0,0,0.08)"
          style={{
            float: 'left',
            color: AccentColor(),
            height: '40px',
          }}
          tabIndex={this.props.tabIndex}
        />
        {deleteAction && (
          <FlatButton onClick={deleteAction} id="close-button" style={{ color: AccentColor() }}>
            {deleteName || getTranslatedString('delete')}
          </FlatButton>
        )}
        {!hideSubmitButton &&
          dirty &&
          showUnsavedChangesIndicator && (
            <StyledChangesLabel>
              {getTranslatedString('footerButtons.unsavedChanges')}
            </StyledChangesLabel>
          )}
        {!hideSubmitButton && (
          <FlatButton
            label={submitName || getTranslatedString('save')}
            onClick={this.onSubmit}
            disabled={isSubmitDisabled}
            primary={true}
            backgroundColor={isSubmitDisabled ? colors.GRAY : AccentColor()}
            hoverColor="grey"
            style={{
              color: colors.WHITE,
              float: 'right',
              height: '40px',
            }}
          />
        )}
      </div>
    );
  }
}

export default connect(
  (state, ownProps) => ({
    dirty: isDirty(ownProps.formName)(state),
    submitting: isSubmitting(ownProps.formName)(state),
  }),
  {
    submit,
  }
)(FooterButtons);
