import { useEffect, useRef, useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import Hellosign from 'hellosign-embedded';
import env from 'config/constants';
import { apiGet, apiPost } from 'helpers/ApiHelper';
import { getCurrentLocale } from 'helpers/i18n';
import { featureEnabled } from 'helpers/ToggleBot';

const noop = () => {};

const validLocales = {
  fr: Hellosign.locales.FR_FR,
  en: Hellosign.locales.EN_US,
  nl: Hellosign.locales.NL_NL,
};

function currentLocale() {
  return validLocales[getCurrentLocale()] || validLocales['en'];
}

function useEmbeddedPreview({ onOpen = noop, onClose = noop, onSend = noop, onError = noop }) {
  const hellosignClient = useRef(new Hellosign({ timeout: 20000 }));

  const getEmbeddedDraftUrlMutation = useMutation({
    mutationKey: ['get_embedded_draft_url'],
    mutationFn({ signatureRequestId }) {
      return apiGet(`/signature_requests/${signatureRequestId}/embedded_draft_url`);
    },
    onSuccess(data) {
      hellosignClient.current.on('open', onOpen);
      hellosignClient.current.on('close', () => {
        // Need to manually unsubscribe when callback is provided
        // https://github.com/scottcorgan/tiny-emitter
        hellosignClient.current.off('open');
        hellosignClient.current.off('send');
        hellosignClient.current.off('close');
        onClose(data);
      });
      hellosignClient.current.on('send', onSend);

      let payload = {
        clientId: data.client_id,
        skipDomainVerification: env.ENV !== 'production',
      };

      if (featureEnabled('hellosign-with-locale')) {
        payload = { ...payload, locale: currentLocale() };
      }

      hellosignClient.current.open(data.url, payload);
    },
    onError: onError,
  });

  return {
    open({ signatureRequestId }) {
      getEmbeddedDraftUrlMutation.mutate({ signatureRequestId });
    },
    isOpen: hellosignClient.current.isOpen,
    isLoading: getEmbeddedDraftUrlMutation.isLoading,
  };
}

function useEmbeddedSigning({
  signatureRequestId,
  cotenantId,
  allowCancel = true,
  onOpen = noop,
  onClose = noop,
  onSign = noop,
  onCancel = noop,
  onError = noop,
}) {
  const hellosignClient = useRef(new Hellosign());
  const [hellosignClientState, setHellosignClientState] = useState({
    isOpen: false,
    isReady: false,
  });

  const getSignUrlMutation = useMutation({
    mutationKey: ['get_sign_url', { signatureRequestId, cotenantId }],
    mutationFn({ signatureRequestId, cotenantId }) {
      return apiGet(`/signature_requests/${signatureRequestId}/sign_url`, {
        cotenant_id: cotenantId,
      });
    },
    onSuccess(data) {
      let payload = {
        clientId: data.client_id,
        skipDomainVerification: env.ENV !== 'production',
        allowCancel: allowCancel,
      };

      if (featureEnabled('hellosign-with-locale')) {
        payload = { ...payload, locale: currentLocale() };
      }

      hellosignClient.current.open(data.sign_url, payload);
    },
    onError: onError,
  });

  const updateSignaturesMutation = useMutation({
    mutationKey: ['update_signatures', signatureRequestId],
    mutationFn({ signatureRequestId }) {
      return apiPost(`/signature_requests/${signatureRequestId}/update_signatures`);
    },
  });

  useEffect(() => {
    const enhancedOnSign = async (data) => {
      try {
        await updateSignaturesMutation.mutateAsync({ signatureRequestId });
      } catch (error) {
        if (env.ENV === 'production') {
          window.Rollbar.error(
            `Couldn't update signatures for signature request ${signatureRequestId}`
          );
        }
      } finally {
        onSign(data);
      }
    };

    hellosignClient.current.on('sign', enhancedOnSign);

    return () => hellosignClient.current.off('sign', enhancedOnSign);
  }, [hellosignClient.current, signatureRequestId, onSign, updateSignaturesMutation]);

  useEffect(() => {
    hellosignClient.current.on('open', onOpen);
    hellosignClient.current.on('close', onClose);
    hellosignClient.current.on('cancel', onCancel);
    hellosignClient.current.on('error', onError);

    return () => {
      hellosignClient.current.off('open', onOpen);
      hellosignClient.current.off('close', onClose);
      hellosignClient.current.off('cancel', onCancel);
      hellosignClient.current.off('error', onError);
    };
  }, [hellosignClient.current, onOpen, onClose, onCancel, onError]);

  useEffect(() => {
    const updateClientState = () => {
      setHellosignClientState({
        isOpen: hellosignClient.current.isOpen,
        isReady: hellosignClient.current.isReady,
      });
    };

    hellosignClient.current.on('open', updateClientState);
    hellosignClient.current.on('close', updateClientState);
    hellosignClient.current.on('cancel', updateClientState);
    hellosignClient.current.on('ready', updateClientState);
    hellosignClient.current.on('error', updateClientState);

    return () => {
      hellosignClient.current.off('open', updateClientState);
      hellosignClient.current.off('close', updateClientState);
      hellosignClient.current.off('cancel', updateClientState);
      hellosignClient.current.off('ready', updateClientState);
      hellosignClient.current.off('error', updateClientState);
    };
  }, [hellosignClient.current]);

  return {
    open() {
      getSignUrlMutation.mutate({ signatureRequestId, cotenantId });
    },
    close() {
      hellosignClient.current.close();
    },
    isOpen: hellosignClientState.isOpen,
    isReady: hellosignClientState.isReady,
    isLoading: getSignUrlMutation.isLoading,
  };
}

function useSignatureStatusCheck({ signatureRequestId, cotenantId, ...props }) {
  const getSignatureStatusMutation = useMutation({
    mutationKey: ['get_signature_status', { signatureRequestId, cotenantId }],
    async mutationFn({ signatureRequestId, cotenantId }) {
      const data = await apiGet(`/signature_requests/${signatureRequestId}/signature_status`, {
        cotenant_id: cotenantId,
      });

      if (data.status === 'signed') {
        return true;
      }

      throw new Error('Signature has not been updated yet');
    },
    retry: 5,
    ...props,
  });

  return {
    isLoading: getSignatureStatusMutation.isLoading,
    isError: getSignatureStatusMutation.isError,
    signed: getSignatureStatusMutation.data,
    startCheck() {
      getSignatureStatusMutation.mutate({ signatureRequestId, cotenantId });
    },
  };
}

// Returns only signature requests where `signer` is needed
function getSignatureRequestIdsFor(signatureProcess, signer) {
  if (!signatureProcess || !signer) return [];

  return signatureProcess.signature_requests
    ?.filter(
      (signatureRequest) =>
        signer.email in signatureRequest.signature_statuses &&
        signatureRequest.signature_statuses[signer.email] !== 'signed'
    )
    ?.map((signatureRequest) => signatureRequest.id);
}

function useSignatureProcess({ signatureProcess, signer }) {
  const signatureRequestIds = getSignatureRequestIdsFor(signatureProcess, signer);
  const [currentSignatureRequestIndex, setCurrentSignatureRequestIndex] = useState(-1);
  const currentSignatureRequestId = signatureRequestIds[currentSignatureRequestIndex];

  return {
    hasUnstartedSignatureRequests:
      signatureRequestIds.length > 0 &&
      currentSignatureRequestIndex < signatureRequestIds.length - 1,
    currentSignatureRequestId,
    goToNextSignatureRequest: () => {
      setCurrentSignatureRequestIndex((currentIndex) => currentIndex + 1);
    },
  };
}

export {
  useEmbeddedPreview,
  useEmbeddedSigning,
  useSignatureStatusCheck,
  currentLocale,
  useSignatureProcess,
};
