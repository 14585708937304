import store from 'store';
import { getClient } from 'launchDarkly';
import constants from 'config/constants';

let developmentOverrides = null;

function getDevelopmentOverrides() {
  if (window.ENV != 'development') {
    return {};
  }

  if (developmentOverrides === null) {
    developmentOverrides = {};

    // Synchronous on purpose!
    // So we can have the overrides available as soon as possible, before anything else happens.
    const base_api_url = `${constants.BASE_API_URL}/v1`;
    const url = `${base_api_url}/feature_set/development_overrides`;

    const request = new XMLHttpRequest();
    request.open('GET', url, false); // 'false' makes the request synchronous
    request.send(null);

    if (request.status === 200) {
      developmentOverrides = JSON.parse(request.responseText);
    }
  }

  return developmentOverrides;
}

export function featureEnabledInDatabase(featureSet, key) {
  if (featureSet[key] && featureSet[key]['enabled'] === true) {
    return true;
  }
  return false;
}

export function featureEnabledInLaunchDarkly(key, defaultValue = false) {
  const client = getClient();

  if (!client) {
    console.warn('[LaunchDarkly]', 'Client not initialized while trying to read a feature flag');
    return false;
  }

  return client.variation(key, defaultValue);
}

// Checks if the feature is enabled in Launch Darkly
// It uses the feature from featureSet within redux as a default value
// Default to false if key does not exist on neither both.
export function featureEnabled(key) {
  const { featureSet } = store.getState().userTypes;

  if (window.ENV == 'development') {
    const overrides = getDevelopmentOverrides();
    if (key in overrides) {
      return !!overrides[key];
    }
  }

  return featureEnabledInLaunchDarkly(key, featureEnabledInDatabase(featureSet, key));
}

// Counts how many of the array of keys are enabled
export function countFeaturesEnabled(keys = []) {
  const { featureSet } = store.getState().userTypes;
  const featureSetKeys = Object.keys(featureSet);

  const launchDarklyClient = getClient();
  const launchDarklyFlags = launchDarklyClient ? launchDarklyClient.allFlags() : {};
  const launchDarklyFlagsKeys = Object.keys(launchDarklyFlags);

  let featuresEnabledCount = 0;

  keys.forEach((key) => {
    if (featureSetKeys.includes(key) && featureSet[key]['enabled']) {
      featuresEnabledCount++;
    } else if (launchDarklyFlagsKeys.includes(key) && launchDarklyFlags[key]) {
      featuresEnabledCount++;
    }
  });

  return featuresEnabledCount;
}
