import queryClient from 'helpers/QueryClient';

export default function reducer(
  state = {
    landlordName: null,
    landlordId: null,
    userType: null,
    isLandlord: false, // DEPRECATED
    isTenant: false, // DEPRECATED
    isOwner: false, // DEPRECATED
    isMaintenance: false, // DEPRECATED
    loginMessage: 'residentLoginPortal',
    passwordResetURL: 'tenant_password_reset',
    passwordRedirectURL: '/dashboard',
    resendConfirmationURL: 'tenant_resend_confirmation',
    changePasswordURL: 'tenant_change_password_reset',
    isThirdPartyManager: false,
    googleConnected: false,
    azureConnected: false,
    permissions: {},
    availability: {},
    userPreferences: {
      payment_reminders: true,
      renewal_reminders: true,
    },
    hasActivePad: false,
    landlordLogoUrl: null,
    accentColor: '#ff3434',
    expiringDialogOpen: true,
    email: null,
    createdSuccess: false,
    provider: 'email',
    landlordSSOEnabled: null, // Global landlord level SSO toggle
    accountProviderIsSSO: null, // User level SSO toggle
    ssoProvidersLandlord: null,
    ssoProvidersAgent: null,
    heroImageUrl: null,
    uuid: null,
    propertyTypes: 'residential',
    userId: null,
    yardiEnabled: false,
    verbiages: {},
    googleTagId: null,
    imageMaxSize: 15.0,
    helpDocuments: {
      landlordPortal: {
        en: 'https://support.happy.co/hc/en-us/sections/16531589314068-Resident-Portal-Yuhu-Legacy-Property-Team',
        fr: 'https://support.happy.co/hc/en-us/sections/16531589314068-Resident-Portal-Yuhu-Legacy-Property-Team',
        nl: 'https://support.happy.co/hc/en-us/sections/16531589314068-Resident-Portal-Yuhu-Legacy-Property-Team',
      },
      residentPortal: {
        en: 'https://support.happy.co/hc/en-us/sections/16531615361684-Resident-Portal-Yuhu-Legacy-Residents-and-Applicants',
        fr: 'http://bit.ly/yuhu-residentportalfr',
        nl: 'https://support.happy.co/hc/en-us/sections/16531615361684-Resident-Portal-Yuhu-Legacy-Residents-and-Applicants',
      },
      default: {
        en: 'https://yuhusupport.helpdocs.io/',
      },
    },
    privacyStatementLink: {
      en: 'https://support.happy.co/hc/en-us/articles/24882368694676-Privacy-Policy',
    },
    privacyStatementUpdatedAt: null,
    termsFileName: 'default.termsConditions',
    showPoweredByYuhu: false,
    featureSet: {},
    canOnlySendByNoticeTemplate: false,
    canAddAmenities: false,
    canApprovePurchaseOrder: false,
    countryCodes: {},
    canAssignAndScheduleTickets: false,
    canLogTravelAndWorkTimes: false,
    canApproveLeases: false,
    canChangeTicketStatus: false,
    greetingNameType: 'first_name',
    landlordCountry: 'Canada',
    globalFilter: 'all',
    leadManagingLink: '',
    landlordErp: undefined,
    isApproverPlus: false,
  },
  action
) {
  switch (action.type) {
    case 'USER_DETAILS::SAVE_PREFERENCES': {
      return {
        ...state,
        userPreferences: action.userPreferences,
      };
    }
    case 'USER_DETAILS::SAVE_NOTIFICATION_PREFERENCES': {
      return {
        ...state,
        notificationPreferences: action.notificationPreferences,
        noticeByEmail: action.noticeByEmail,
        digitalLegalDocuments: action.digitalLegalDocuments,
      };
    }
    case 'USER_DETAILS::SAVE_AVAILABLITY': {
      return { ...state, availability: action.availability };
    }
    case 'USER_TYPES::SET_STRIPE_CONNECTED': {
      return { ...state, stripeConnected: true };
    }
    case 'USER_TYPES::SET_GOOGLE_CONNECTED': {
      return {
        ...state,
        googleConnected: true,
        azureConnected: false,
      };
    }
    case 'USER_TYPES::SET_AZURE_CONNECTED': {
      return {
        ...state,
        azureConnected: true,
        googleConnected: false,
      };
    }
    case 'USER_TYPES::UPDATE_CALENDAR_CONNECTIONS': {
      return {
        ...state,
        azureConnected: action.azureConnected,
        googleConnected: action.googleConnected,
      };
    }
    case 'USER_TYPES::SET_LANDLORD_LOGO': {
      return {
        ...state,
        landlordLogoUrl: action.landlordLogoUrl,
        landlordLogoThumbUrl: action.landlordLogoThumbUrl,
      };
    }
    case 'USER_TYPES::SET_DEFAULT_LANGUAGES': {
      return {
        ...state,
        defaultLanguages: action.defaultLanguages || state.defaultLanguages,
      };
    }
    case 'USER_TYPES::HIDE_EXPIRING_PASSWORD_DIALOG': {
      return { ...state, expiringDialogOpen: false };
    }
    case 'USER_TYPES::HIDE_INITIAL_LEAD_DIALOG': {
      return { ...state, initialDialogOpen: false };
    }
    case 'USER_TYPES::SET_ACCENT_COLOR': {
      return {
        ...state,
        accentColor: action.accentColor || state.accentColor,
      };
    }
    case 'USER_TYPES::SET_GOOGLE_TAG_ID': {
      return {
        ...state,
        googleTagId: action.googleTagId || state.googleTagId,
      };
    }
    case 'USER_TYPES::SET_PROPERTY_TYPE': {
      return { ...state, propertyTypes: action.propertyTypes };
    }
    case 'USER_TYPES::SET_USER_AS_LEAD': {
      const newState = {
        ...state,
        firstName: action.firstName,
        lastName: action.lastName,
        userType: 'lead',
        userId: action.id,
        isLandlord: false, // DEPRECATED
        isTenant: true, // DEPRECATED
        isOwner: false, // DEPRECATED
        loginAuth: null,
        isMaintenance: false, // DEPRECATED
        loginMessage: 'residentLoginPortal',
        passwordResetURL: 'tenant_password_reset',
        resendConfirmationURL: 'tenant_resend_confirmation',
        passwordRedirectURL: '/dashboard',
        changePasswordURL: 'tenant_change_password_reset',
        hasActivePad: action.hasActivePad,
        accentColor: action.accentColor || state.accentColor,
        activeLeaseHasAmenities: action.activeLeaseHasAmenities,
        moveInDate: action.moveInDate,
        initialDialogOpen: true,
        mustAcceptTerms: action.mustAcceptTerms,
        landlordName: action.landlordName,
        showHelp: action.showHelp,
        landlordId: action.landlordId || state.landlordId,
        propertyTypes: action.propertyTypes,
        verbiages: action.verbiages || state.verbiages,
        imageMaxSize: action.imageMaxSize || 15.0,
        landlordLogoUrl: action.landlordLogoUrl || state.landlordLogoUrl,
        landlordLogoThumbUrl: action.landlordLogoThumbUrl || state.landlordLogoThumbUrl,
        helpDocuments: action.helpDocuments || state.helpDocuments,
        privacyStatementLink: action.privacyStatementLink || state.privacyStatementLink,
        privacyStatementUpdatedAt:
          action.privacyStatementUpdatedAt || state.privacyStatementUpdatedAt,
        termsFileName: action.termsFileName || state.termsFileName,
        showPoweredByYuhu: action.showPoweredByYuhu || state.showPoweredByYuhu,
        heroImageUrl: action.heroImageUrl || state.heroImageUrl,
        featureSet: action.featureSet || state.featureSet,
        defaultLanguages: action.defaultLanguages || state.defaultLanguages,
        countryCodes: action.countryCodes || state.countryCodes,
        greetingNameType: action.greetingNameType || state.greetingNameType,
        googleTagId: action.googleTagId || state.googleTagId,
        landlordCountry: action.landlordCountry || state.landlordCountry,
        globalFilter: action.globalFilter || state.globalFilter,
        landlordModuleAccess: action.landlordModuleAccess || state.landlordModuleAccess,
      };

      queryClient.setQueryData(['current_user'], () => newState);

      return newState;
    }
    case 'USER_TYPES::SET_USER_AS_LANDLORD': {
      const permissions = action.permissions || state.permissions;
      const active = action.active || state.active;

      const newState = {
        ...state,
        firstName: action.firstName,
        lastName: action.lastName,
        admin: action.admin,
        isYuhu: action.isYuhu,
        isSystemAdministrator: action.isSystemAdministrator || state.isSystemAdministrator,
        landlordModuleAccess: action.landlordModuleAccess || state.landlordModuleAccess,
        landlordBackgroundChecks: action.landlordBackgroundChecks || state.landlordBackgroundChecks,
        accentColor: action.accentColor || state.accentColor,
        userType: 'agent',
        userId: action.id,
        allowManualBooking: action.allowManualBooking,
        stripeConnected: action.stripeConnected,
        googleConnected: action.googleConnected,
        azureConnected: action.azureConnected,
        active,
        showAvailability: action.showAvailability,
        isLandlord: true, // DEPRECATED
        isTenant: false, // DEPRECATED
        isOwner: false, // DEPRECATED
        loginMessage: 'landlordLoginPortal',
        loginAuth: 'landlord',
        isMaintenance: false, // DEPRECATED
        permissions: permissions,
        passwordResetURL: 'companies/agent_password_reset',
        resendConfirmationURL: 'companies/agent_resend_confirmation',
        passwordRedirectURL: '/companies/dashboard',
        changePasswordURL: 'companies/agent_change_password_reset',
        passwordExpirationDate: action.passwordExpirationDate,
        enableAllAgentsTwoFactorAuth: action.enableAllAgentsTwoFactorAuth,
        isThirdPartyManager: action.isThirdPartyManager,
        expiringDialogOpen: true,
        accountingEnabled: action.accountingEnabled,
        maintenanceEnabled: action.maintenanceEnabled,
        multiFamilyEnabled: action.multiFamilyEnabled,
        email: action.email,
        createdSuccess: action.createdSuccess,
        noticeTemplateManager: action.noticeTemplateManager,
        provider: action.provider,
        showHelp: action.showHelp,
        showMessaging: action.showMessaging,
        showTenantAdditionalOccupant: action.showTenantAdditionalOccupant,
        showTenantDocuments: action.showTenantDocuments,
        showTenantLedger: action.showTenantLedger,
        showTenantPetDetails: action.showTenantPetDetails,
        landlordId: action.landlordId || state.landlordId,
        landlordSSOEnabled: state.landlordSSOEnabled || action.landlordSSOEnabled,
        accountProviderIsSSO: action.provider && action.provider !== 'username',
        uuid: state.uuid || action.uuid,
        propertyTypes: action.propertyTypes,
        verbiages: action.verbiages || state.verbiages,
        yardiEnabled: action.yardiEnabled,
        ssoProvidersAgent: action.sso_providers || state.ssoProvidersAgent,
        imageMaxSize: action.imageMaxSize || 15.0,
        helpDocuments: action.helpDocuments || state.helpDocuments,
        showPoweredByYuhu: action.showPoweredByYuhu || state.showPoweredByYuhu,
        heroImageUrl: action.heroImageUrl || state.heroImageUrl,
        featureSet: action.featureSet || state.featureSet,
        canOnlySendByNoticeTemplate: action.canOnlySendByNoticeTemplate,
        canAddAmenities: action.canAddAmenities,
        canApprovePurchaseOrder: action.canApprovePurchaseOrder,
        defaultLanguages: action.defaultLanguages || state.defaultLanguages,
        countryCodes: action.countryCodes || state.countryCodes,
        canAssignAndScheduleTickets: action.canAssignAndScheduleTickets,
        canAssignTicketsMaint: action.canAssignTicketsMaint,
        canLogTravelAndWorkTimes: action.canLogTravelAndWorkTimes,
        canApproveLeases: action.canApproveLeases,
        canApproveApplication: action.canApproveApplication,
        canReviewApplication: action.canReviewApplication,
        canViewCreditCheck: action.canViewCreditCheck,
        canChangeTicketStatus: action.canChangeTicketStatus,
        paymentProvider: action.paymentProvider,
        greetingNameType: action.greetingNameType || state.greetingNameType,
        googleTagId: action.googleTagId || state.googleTagId,
        landlordCountry: action.landlordCountry || state.landlordCountry,
        globalFilter: action.globalFilter || state.globalFilter,
        leadManagingLink: action.leadManagingLink || state.leadManagingLink,
        landlordErp: action.landlordErp || state.landlordErp,
        isApproverPlus: action.isApproverPlus ?? state.isApproverPlus,
        paymentsModuleActive: action.paymentsModuleActive ?? state.paymentsModuleActive,
        canViewUnassignFilterInApplications: action.canViewUnassignFilterInApplications,
      };

      queryClient.setQueryData(['current_user'], () => newState);

      return newState;
    }
    case 'USER_TYPES::SET_USER_AS_MAINTENANCE': {
      const active = action.active || state.active;

      const newState = {
        ...state,
        firstName: action.firstName,
        lastName: action.lastName,
        userType: 'maintenance',
        userId: action.id,
        active,
        isLandlord: false, // DEPRECATED
        isTenant: false, // DEPRECATED
        isOwner: false, // DEPRECATED
        isMaintenance: true, // DEPRECATED
        loginMessage: 'maintenanceLoginPortal',
        loginAuth: 'maintenance',
        passwordResetURL: 'maintenance/maintenance_password_reset',
        resendConfirmationURL: 'maintenance/maintenance_resend_confirmation',
        passwordRedirectURL: '/maintenance/dashboard',
        changePasswordURL: 'maintenance/maintenance_change_password_reset',
        accentColor: action.accentColor || state.accentColor,
        readOnly: action.readOnly,
        claimTickets: action.claimTickets,
        assignTickets: action.assignTickets,
        createdSuccess: action.createdSuccess,
        landlordId: action.landlordId || state.landlordId,
        propertyTypes: action.propertyTypes,
        admin: action.admin,
        verbiages: action.verbiages || state.verbiages,
        landlordSSOEnabled: action.landlordSSOEnabled || state.landlordSSOEnabled,
        accountProviderIsSSO: action.provider && action.provider !== 'username',
        imageMaxSize: action.imageMaxSize || 15.0,
        helpDocuments: action.helpDocuments || state.helpDocuments,
        showPoweredByYuhu: action.showPoweredByYuhu || state.showPoweredByYuhu,
        heroImageUrl: action.heroImageUrl || state.heroImageUrl,
        featureSet: action.featureSet || state.featureSet,
        defaultLanguages: action.defaultLanguages || state.defaultLanguages,
        countryCodes: action.countryCodes || state.countryCodes,
        googleTagId: action.googleTagId || state.googleTagId,
        landlordCountry: action.landlordCountry || state.landlordCountry,
        globalFilter: action.globalFilter || state.globalFilter,
        canAssignTicketsMaint: action.canAssignTicketsMaint || state.canAssignTicketsMaint,
        landlordModuleAccess: action.landlordModuleAccess || state.landlordModuleAccess,
      };

      queryClient.setQueryData(['current_user'], () => newState);

      return newState;
    }
    case 'USER_TYPES::SET_LANDLORD_DETAILS': {
      const ssoProviders = ['okta', 'azure'];

      const newState = {
        ...state,
        landlordName: action.landlordName,
        landlordId: action.landlordId,
        landlordLogoUrl: action.landlordLogoUrl,
        landlordLogoThumbUrl: action.landlordLogoThumbUrl,
        accentColor: action.accentColor || state.accentColor,
        landlordSSOEnabled: action.landlordSSOEnabled || state.landlordSSOEnabled,
        accountProviderIsSSO: action.provider && ssoProviders.includes(action.provider),
        uuid: state.uuid || action.uuid,
        propertyTypes: action.propertyTypes,
        ssoProvidersLandlord: action.sso_providers || state.ssoProvidersLandlord,
        verbiages: action.verbiages || state.verbiages,
        showPoweredByYuhu: action.showPoweredByYuhu || state.showPoweredByYuhu,
        heroImageUrl: action.heroImageUrl || state.heroImageUrl,
        featureSet: action.featureSet || state.featureSet,
        defaultLanguages: action.defaultLanguages || state.defaultLanguages,
        countryCodes: action.countryCodes || state.countryCodes,
        greetingNameType: action.greetingNameType || state.greetingNameType,
        googleTagId: action.googleTagId || state.googleTagId,
        landlordCountry: action.landlordCountry || state.landlordCountry,
        globalFilter: action.globalFilter || state.globalFilter,
        leadManagingLink: action.leadManagingLink || state.leadManagingLink,
      };

      queryClient.setQueryData(['current_user'], () => newState);

      return newState;
    }
    default:
      return state;
  }
}
