import * as FullStory from '@fullstory/browser';
import { initializeClient } from 'launchDarkly';
import { hostIsCapreitCanada } from 'helpers/CurrentWindowLocation';

const config = {};
export default config;

function configureRollbar() {
  if (['production', 'dev', 'qa', 'staging', 'training'].includes(window.ENV)) {
    var _rollbarConfig = {
      accessToken: '3f37ebe11fd3409ba0436bbd38a8a89c',
      payload: {
        environment:
          window.CLIENT_URL === 'yuhu.io' ? window.ENV : window.ENV + '-' + window.CLIENT_URL,
        client: {
          javascript: {
            captureUncaught: true,
            captureUnhandledRejections: true,
            source_map_enabled: true,
            guess_uncaught_frames: true,
            code_version: window.CODE_VERSION,
          },
        },
      },
      transform: function (payload) {
        var trace = payload.body.trace;
        var locRegex = new RegExp('(https?)://[a-zA-Z0-9._-]+.' + window.CLIENT_URL + '(.*)');
        if (trace && trace.frames) {
          for (var i = 0; i < trace.frames.length; i++) {
            var filename = trace.frames[i].filename;
            if (filename) {
              var m = filename.match(locRegex);
              // Be sure that the minified_url when uploading includes 'dynamichost'
              trace.frames[i].filename = m[1] + '://dynamichost' + m[2];
            }
          }
        }
      },
    };
    // Rollbar Snippet
    !(function (r) {
      var e = {};
      function o(n) {
        if (e[n]) return e[n].exports;
        var t = (e[n] = { i: n, l: !1, exports: {} });
        return r[n].call(t.exports, t, t.exports, o), (t.l = !0), t.exports;
      }
      (o.m = r),
        (o.c = e),
        (o.d = function (r, e, n) {
          o.o(r, e) || Object.defineProperty(r, e, { enumerable: !0, get: n });
        }),
        (o.r = function (r) {
          'undefined' != typeof Symbol &&
            Symbol.toStringTag &&
            Object.defineProperty(r, Symbol.toStringTag, { value: 'Module' }),
            Object.defineProperty(r, '__esModule', { value: !0 });
        }),
        (o.t = function (r, e) {
          if ((1 & e && (r = o(r)), 8 & e)) return r;
          if (4 & e && 'object' == typeof r && r && r.__esModule) return r;
          var n = Object.create(null);
          if (
            (o.r(n),
            Object.defineProperty(n, 'default', { enumerable: !0, value: r }),
            2 & e && 'string' != typeof r)
          )
            for (var t in r)
              o.d(
                n,
                t,
                function (e) {
                  return r[e];
                }.bind(null, t)
              );
          return n;
        }),
        (o.n = function (r) {
          var e =
            r && r.__esModule
              ? function () {
                  return r.default;
                }
              : function () {
                  return r;
                };
          return o.d(e, 'a', e), e;
        }),
        (o.o = function (r, e) {
          return Object.prototype.hasOwnProperty.call(r, e);
        }),
        (o.p = ''),
        o((o.s = 0));
    })([
      function (r, e, o) {
        var n = o(1),
          t = o(4);
        (_rollbarConfig = _rollbarConfig || {}),
          (_rollbarConfig.rollbarJsUrl =
            _rollbarConfig.rollbarJsUrl ||
            'https://cdnjs.cloudflare.com/ajax/libs/rollbar.js/2.26.1/rollbar.min.js'),
          (_rollbarConfig.async = void 0 === _rollbarConfig.async || _rollbarConfig.async);
        var a = n.setupShim(window, _rollbarConfig),
          l = t(_rollbarConfig);
        (window.rollbar = n.Rollbar),
          a.loadFull(window, document, !_rollbarConfig.async, _rollbarConfig, l);
      },
      function (r, e, o) {
        var n = o(2);
        function t(r) {
          return function () {
            try {
              return r.apply(this, arguments);
            } catch (r) {
              try {
                console.error('[Rollbar]: Internal error', r);
              } catch (r) {}
            }
          };
        }
        var a = 0;
        function l(r, e) {
          (this.options = r), (this._rollbarOldOnError = null);
          var o = a++;
          (this.shimId = function () {
            return o;
          }),
            'undefined' != typeof window &&
              window._rollbarShims &&
              (window._rollbarShims[o] = { handler: e, messages: [] });
        }
        var i = o(3),
          d = function (r, e) {
            return new l(r, e);
          },
          c = function (r) {
            return new i(d, r);
          };
        function s(r) {
          return t(function () {
            var e = Array.prototype.slice.call(arguments, 0),
              o = { shim: this, method: r, args: e, ts: new Date() };
            window._rollbarShims[this.shimId()].messages.push(o);
          });
        }
        (l.prototype.loadFull = function (r, e, o, n, a) {
          var l = !1,
            i = e.createElement('script'),
            d = e.getElementsByTagName('script')[0],
            c = d.parentNode;
          (i.crossOrigin = ''),
            (i.src = n.rollbarJsUrl),
            o || (i.async = !0),
            (i.onload = i.onreadystatechange =
              t(function () {
                if (
                  !(
                    l ||
                    (this.readyState &&
                      'loaded' !== this.readyState &&
                      'complete' !== this.readyState)
                  )
                ) {
                  i.onload = i.onreadystatechange = null;
                  try {
                    c.removeChild(i);
                  } catch (r) {}
                  (l = !0),
                    (function () {
                      var e;
                      if (void 0 === r._rollbarDidLoad) {
                        e = new Error('rollbar.js did not load');
                        for (var o, n, t, l, i = 0; (o = r._rollbarShims[i++]); )
                          for (o = o.messages || []; (n = o.shift()); )
                            for (t = n.args || [], i = 0; i < t.length; ++i)
                              if ('function' == typeof (l = t[i])) {
                                l(e);
                                break;
                              }
                      }
                      'function' == typeof a && a(e);
                    })();
                }
              })),
            c.insertBefore(i, d);
        }),
          (l.prototype.wrap = function (r, e, o) {
            try {
              var n;
              if (
                ((n =
                  'function' == typeof e
                    ? e
                    : function () {
                        return e || {};
                      }),
                'function' != typeof r)
              )
                return r;
              if (r._isWrap) return r;
              if (
                !r._rollbar_wrapped &&
                ((r._rollbar_wrapped = function () {
                  o && 'function' == typeof o && o.apply(this, arguments);
                  try {
                    return r.apply(this, arguments);
                  } catch (o) {
                    var e = o;
                    throw (
                      (e &&
                        ('string' == typeof e && (e = new String(e)),
                        (e._rollbarContext = n() || {}),
                        (e._rollbarContext._wrappedSource = r.toString()),
                        (window._rollbarWrappedError = e)),
                      e)
                    );
                  }
                }),
                (r._rollbar_wrapped._isWrap = !0),
                r.hasOwnProperty)
              )
                for (var t in r) r.hasOwnProperty(t) && (r._rollbar_wrapped[t] = r[t]);
              return r._rollbar_wrapped;
            } catch (e) {
              return r;
            }
          });
        for (
          var p =
              'log,debug,info,warn,warning,error,critical,global,configure,handleUncaughtException,handleUnhandledRejection,captureEvent,captureDomContentLoaded,captureLoad'.split(
                ','
              ),
            u = 0;
          u < p.length;
          ++u
        )
          l.prototype[p[u]] = s(p[u]);
        r.exports = {
          setupShim: function (r, e) {
            if (r) {
              var o = e.globalAlias || 'Rollbar';
              if ('object' == typeof r[o]) return r[o];
              (r._rollbarShims = {}), (r._rollbarWrappedError = null);
              var a = new c(e);
              return t(function () {
                e.captureUncaught &&
                  ((a._rollbarOldOnError = r.onerror),
                  n.captureUncaughtExceptions(r, a, !0),
                  n.wrapGlobals(r, a, !0)),
                  e.captureUnhandledRejections && n.captureUnhandledRejections(r, a, !0);
                var t = e.autoInstrument;
                return (
                  !1 !== e.enabled &&
                    (void 0 === t || !0 === t || ('object' == typeof t && t.network)) &&
                    r.addEventListener &&
                    (r.addEventListener('load', a.captureLoad.bind(a)),
                    r.addEventListener('DOMContentLoaded', a.captureDomContentLoaded.bind(a))),
                  (r[o] = a),
                  a
                );
              })();
            }
          },
          Rollbar: c,
        };
      },
      function (r, e) {
        function o(r, e, o) {
          if (e.hasOwnProperty && e.hasOwnProperty('addEventListener')) {
            for (var n = e.addEventListener; n._rollbarOldAdd && n.belongsToShim; )
              n = n._rollbarOldAdd;
            var t = function (e, o, t) {
              n.call(this, e, r.wrap(o), t);
            };
            (t._rollbarOldAdd = n), (t.belongsToShim = o), (e.addEventListener = t);
            for (var a = e.removeEventListener; a._rollbarOldRemove && a.belongsToShim; )
              a = a._rollbarOldRemove;
            var l = function (r, e, o) {
              a.call(this, r, (e && e._rollbar_wrapped) || e, o);
            };
            (l._rollbarOldRemove = a), (l.belongsToShim = o), (e.removeEventListener = l);
          }
        }
        r.exports = {
          captureUncaughtExceptions: function (r, e, o) {
            if (r) {
              var n;
              if ('function' == typeof e._rollbarOldOnError) n = e._rollbarOldOnError;
              else if (r.onerror) {
                for (n = r.onerror; n._rollbarOldOnError; ) n = n._rollbarOldOnError;
                e._rollbarOldOnError = n;
              }
              var t = function () {
                var o = Array.prototype.slice.call(arguments, 0);
                !(function (r, e, o, n) {
                  r._rollbarWrappedError &&
                    (n[4] || (n[4] = r._rollbarWrappedError),
                    n[5] || (n[5] = r._rollbarWrappedError._rollbarContext),
                    (r._rollbarWrappedError = null)),
                    e.handleUncaughtException.apply(e, n),
                    o && o.apply(r, n);
                })(r, e, n, o);
              };
              o && (t._rollbarOldOnError = n), (r.onerror = t);
            }
          },
          captureUnhandledRejections: function (r, e, o) {
            if (r) {
              'function' == typeof r._rollbarURH &&
                r._rollbarURH.belongsToShim &&
                r.removeEventListener('unhandledrejection', r._rollbarURH);
              var n = function (r) {
                var o, n, t;
                try {
                  o = r.reason;
                } catch (r) {
                  o = void 0;
                }
                try {
                  n = r.promise;
                } catch (r) {
                  n = '[unhandledrejection] error getting `promise` from event';
                }
                try {
                  (t = r.detail), !o && t && ((o = t.reason), (n = t.promise));
                } catch (r) {}
                o || (o = '[unhandledrejection] error getting `reason` from event'),
                  e && e.handleUnhandledRejection && e.handleUnhandledRejection(o, n);
              };
              (n.belongsToShim = o),
                (r._rollbarURH = n),
                r.addEventListener('unhandledrejection', n);
            }
          },
          wrapGlobals: function (r, e, n) {
            if (r) {
              var t,
                a,
                l =
                  'EventTarget,Window,Node,ApplicationCache,AudioTrackList,ChannelMergerNode,CryptoOperation,EventSource,FileReader,HTMLUnknownElement,IDBDatabase,IDBRequest,IDBTransaction,KeyOperation,MediaController,MessagePort,ModalWindow,Notification,SVGElementInstance,Screen,TextTrack,TextTrackCue,TextTrackList,WebSocket,WebSocketWorker,Worker,XMLHttpRequest,XMLHttpRequestEventTarget,XMLHttpRequestUpload'.split(
                    ','
                  );
              for (t = 0; t < l.length; ++t)
                r[(a = l[t])] && r[a].prototype && o(e, r[a].prototype, n);
            }
          },
        };
      },
      function (r, e) {
        function o(r, e) {
          (this.impl = r(e, this)),
            (this.options = e),
            (function (r) {
              for (
                var e = function (r) {
                    return function () {
                      var e = Array.prototype.slice.call(arguments, 0);
                      if (this.impl[r]) return this.impl[r].apply(this.impl, e);
                    };
                  },
                  o =
                    'log,debug,info,warn,warning,error,critical,global,configure,handleUncaughtException,handleUnhandledRejection,_createItem,wrap,loadFull,shimId,captureEvent,captureDomContentLoaded,captureLoad'.split(
                      ','
                    ),
                  n = 0;
                n < o.length;
                n++
              )
                r[o[n]] = e(o[n]);
            })(o.prototype);
        }
        (o.prototype._swapAndProcessMessages = function (r, e) {
          var o, n, t;
          for (this.impl = r(this.options); (o = e.shift()); )
            (n = o.method),
              (t = o.args),
              this[n] &&
                'function' == typeof this[n] &&
                ('captureDomContentLoaded' === n || 'captureLoad' === n
                  ? this[n].apply(this, [t[0], o.ts])
                  : this[n].apply(this, t));
          return this;
        }),
          (r.exports = o);
      },
      function (r, e) {
        r.exports = function (r) {
          return function (e) {
            if (!e && !window._rollbarInitialized) {
              for (
                var o,
                  n,
                  t = (r = r || {}).globalAlias || 'Rollbar',
                  a = window.rollbar,
                  l = function (r) {
                    return new a(r);
                  },
                  i = 0;
                (o = window._rollbarShims[i++]);

              )
                n || (n = o.handler), o.handler._swapAndProcessMessages(l, o.messages);
              (window[t] = n), (window._rollbarInitialized = !0);
            }
          };
        };
      },
    ]);
    // End Rollbar Snippet
  } else {
    // if not in prod, just mock rollbar
    window.Rollbar = {
      error: (msg, error) => {
        return;
      },
    };
  }
}

function configureGoogleTagManager({ tagId, enabled }) {
  if (enabled) {
    // Addig google tag manager script if capreit
    var tagManager = document.createElement('script');
    tagManager.src = `https://www.googletagmanager.com/gtag/js?id=${tagId}`;
    tagManager.async = true;
    var firstScriptTag = document.getElementsByTagName('script')[0];
    firstScriptTag.parentNode.insertBefore(tagManager, firstScriptTag);

    // <!-- Global site tag (gtag.js) - Google Analytics -->
    window.dataLayer = window.dataLayer || [];
    function gtag() {
      dataLayer.push(arguments);
    }
    gtag('js', new Date());

    gtag('config', tagId);
    window.gtag = gtag;
  } else {
    // if not in prod, just mock gtag
    window.gtag = (config, id, page) => {
      return;
    };
  }
}

function configureMicrosoftClarity({ tagId, enabled }) {
  if (enabled) {
    (function (c, l, a, r, i, t, y) {
      c[a] =
        c[a] ||
        function () {
          (c[a].q = c[a].q || []).push(arguments);
        };
      t = l.createElement(r);
      t.async = 1;
      t.src = 'https://www.clarity.ms/tag/' + i;
      y = l.getElementsByTagName(r)[0];
      y.parentNode.insertBefore(t, y);
    })(window, document, 'clarity', 'script', tagId);
  }
}

function configureGainsight() {
  if (!window.location.pathname.startsWith('/companies') || !window.GAINSIGHT_TAG) return;

  (function (n, t, a, e, co) {
    var i = 'aptrinsic';
    (n[i] =
      n[i] ||
      function () {
        (n[i].q = n[i].q || []).push(arguments);
      }),
      (n[i].p = e);
    n[i].c = co;
    var r = t.createElement('script');
    (r.async = !0), (r.src = a + '?a=' + e);
    var c = t.getElementsByTagName('script')[0];
    c.parentNode.insertBefore(r, c);
  })(window, document, 'https://web-sdk.aptrinsic.com/api/aptrinsic.js', window.GAINSIGHT_TAG);
}

function setConfigForCircleCIDeploy() {
  for (let prop in config) {
    delete config[prop];
  }
  config['env'] = process.env.REACT_APP_ENV;
  config['customApiSubdomain'] = process.env.REACT_APP_CUSTOM_API_SUBDOMAIN;
  config['clientUrl'] = process.env.REACT_APP_CLIENT_URL;
  config['launchDarklyKey'] = process.env.REACT_APP_LAUNCH_DARKLY_KEY;
  config['gainsightTag'] = process.env.REACT_APP_GAINSIGHT_TAG;
  config['defaultEnvSubdomain'] = process.env.REACT_APP_DEFAULT_ENV_SUBDOMAIN;
  config['fusionAuthEnabled'] = process.env.REACT_APP_FUSION_AUTH === 'true';
  config['happyExperienceSwitcherGraphEnabled'] =
    process.env.REACT_APP_HAPPY_EXPERIENCE_SWITCHER_GRAPH_ENABLED === 'true';

  window.ENV = process.env.REACT_APP_ENV;
  window.CUSTOM_API_SUBDOMAIN = process.env.REACT_APP_CUSTOM_API_SUBDOMAIN;
  window.CLIENT_URL = process.env.REACT_APP_CLIENT_URL;
  window.LAUNCH_DARKLY_KEY = process.env.REACT_APP_LAUNCH_DARKLY_KEY;
  window.CODE_VERSION = process.env.REACT_APP_CODE_VERSION;
  window.GAINSIGHT_TAG = process.env.REACT_APP_GAINSIGHT_TAG;
  window.DEFAULT_ENV_SUBDOMAIN = process.env.REACT_APP_DEFAULT_ENV_SUBDOMAIN;
  window.FUSION_AUTH_ENABLED = process.env.REACT_APP_FUSION_AUTH === 'true';
  window.HAPPY_EXPERIENCE_SWITCHER_GRAPH_ENABLED =
    process.env.REACT_APP_HAPPY_EXPERIENCE_SWITCHER_GRAPH_ENABLED === 'true';

  return config;
}

function setConfigForBuildOnce() {
  return fetch('/config.json')
    .then((result) => result.json())
    .then((newconfig) => {
      for (let prop in config) {
        delete config[prop];
      }
      for (let prop in newconfig) {
        config[prop] = newconfig[prop];
      }

      window.ENV = newconfig['env'];
      window.CUSTOM_API_SUBDOMAIN = newconfig['customApiSubdomain'];
      window.CLIENT_URL = newconfig['clientUrl'];
      window.LAUNCH_DARKLY_KEY = newconfig['launchDarklyKey'];
      window.GAINSIGHT_TAG = newconfig['gainsightTag'];
      window.DEFAULT_ENV_SUBDOMAIN = newconfig['defaultEnvSubdomain'];
      window.CODE_VERSION = process.env.REACT_APP_CODE_VERSION;

      return config;
    })
    .catch((error) => {});
}

function getLandlordBySubdomain() {
  let constants = require('config/constants');
  constants = constants.default ?? constants;

  const subdomain = constants.SUBDOMAIN || 'yuhu';
  const apiAgentUrl = `${constants.BASE_API_URL}/v1`;

  return fetch(`${apiAgentUrl}/landlords/domains/${subdomain}`).then((response) => response.json());
}

async function initializeLaunchDarklyClient() {
  try {
    const { landlord_details: landlord, country_iso_code } = await getLandlordBySubdomain();

    if (country_iso_code) {
      localStorage.setItem('country_iso_code', country_iso_code);
    }
    await initializeClient({ key: landlord.id, name: landlord.name });
  } catch (error) {
    console.warn('[LaunchDarkly]', "Couldn't initialize LaunchDarkly client on config stage.");
  }
}

async function loadFontFixes() {
  let ToggleBot = require('helpers/ToggleBot');
  if (ToggleBot.featureEnabled('font-fix')) {
    await import('../font-fixes.css');
  } else {
    await import('../old-fonts.css');
  }
}

function configureAccessWidget({ enabled }) {
  if (!enabled) return;
  //     <script> (function(){ var s = document.createElement('script'); var h = document.querySelector('head') || document.body; s.src = 'https://acsbapp.com/apps/app/dist/js/app.js'; s.async = true; s.onload = function(){ acsbJS.init({ statementLink : '', footerHtml : '', hideMobile : false, hideTrigger : false, disableBgProcess : false, language : 'en', position : 'right', leadColor : '#146FF8', triggerColor : '#146FF8', triggerRadius : '50%', triggerPositionX : 'right', triggerPositionY : 'bottom', triggerIcon : 'people', triggerSize : 'bottom', triggerOffsetX : 20, triggerOffsetY : 20, mobile : { triggerSize : 'small', triggerPositionX : 'right', triggerPositionY : 'bottom', triggerOffsetX : 10, triggerOffsetY : 10, triggerRadius : '20' } }); }; h.appendChild(s); })(); </script>

  (function () {
    var s = document.createElement('script');
    var h = document.querySelector('head') || document.body;
    s.src = 'https://acsbapp.com/apps/app/dist/js/app.js';
    s.async = true;
    s.onload = function () {
      acsbJS.init({
        statementLink: '',
        footerHtml: '',
        hideMobile: false,
        hideTrigger: false,
        disableBgProcess: false,
        language: 'en',
        position: 'right',
        leadColor: '#146FF8',
        triggerColor: '#146FF8',
        triggerRadius: '50%',
        triggerPositionX: 'right',
        triggerPositionY: 'bottom',
        triggerIcon: 'people',
        triggerSize: 'bottom',
        triggerOffsetX: 20,
        triggerOffsetY: 20,
        mobile: {
          triggerSize: 'small',
          triggerPositionX: 'right',
          triggerPositionY: 'bottom',
          triggerOffsetX: 10,
          triggerOffsetY: 10,
          triggerRadius: '20',
        },
      });
    };
    h.appendChild(s);
  })();
}

function load() {
  return new Promise(async (resolve) => {
    let config;
    if (process.env.REACT_APP_BUILD_ONCE === 'true') {
      config = await setConfigForBuildOnce();
    } else {
      config = await setConfigForCircleCIDeploy();
    }

    await initializeLaunchDarklyClient();

    await loadFontFixes();

    configureGoogleTagManager({
      tagId: 'UA-146080885-2',
      enabled: window.ENV === 'production' && hostIsCapreitCanada(),
    });

    configureGoogleTagManager({
      tagId: 'G-SCJKKVH67T',
      enabled: window.ENV === 'production',
    });

    configureMicrosoftClarity({
      tagId: 'gjbof42ixe',
      enabled: window.ENV === 'production',
    });

    configureRollbar();
    configureGainsight();
    FullStory.init({ orgId: '157CGG', devMode: window.ENV !== 'production' });
    configureAccessWidget({ enabled: window.ENV === 'production' && hostIsCapreitCanada() });

    resolve(config);
  });
}
export { load };
